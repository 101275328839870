import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";

const Voucher = (props) =>
{
	const context = useContext(AuthContext);
	const [customerName, setCustomerName] = useState("");
	const [globalState] = useStore();
	
	useEffect(() =>
	{
		if (props?.data?.cashbookId)
		{
			let cloneCustomers = [...globalState.customers];
			let customer = cloneCustomers.find(per => per.id == props.data.cashbookId);
			setCustomerName([customer.customer.firstName, customer.customer.lastName].join(" "));
		}
	}, [props?.data?.cashbookId])

  return (
		<div style={{background: "white", maxWidth: "80%", margin: "0 auto", border: "1px solid rgba(0, 0, 0, 0.5)"}} className={["p-4 px-5", styles.container].join(" ")}>
			<p className="text-center fs-2">Payment Voucher</p>
			<div className="row gap-3">
				<div>
					<span>Date: </span>
					<span>{new Date(new Date()).toLocaleString()}</span>
				</div>
				<div>
					<span>Amount: </span>
					<span>{props.data.amount} {context?.currency?.code}</span>
				</div>
				<div>
					<span>Type: </span>
					<span>{props.data.type ? "Cash In" : "Cash Out"}</span>
				</div>
				<div>
					<span>Reciver: </span>
					<span>{customerName}</span>
				</div>
			</div>

			<div className={[styles.nameContainer, "row align-items-center justify-content-between"].join(" ")}>
				<p className="col-lg-3 col-md-3 col-3">{[context?.customer?.firstName, context?.customer?.lastName].join(" ")}</p>
				<p className="col-lg-3 col-md-3 col-3">{customerName}</p>
			</div>
		</div>
	)
};

export default Voucher;