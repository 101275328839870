import React, { useContext, useEffect, useState } from 'react';
import styles from "./style.module.css";
import { IoIosArrowDown } from "react-icons/io";
import { AuthContext } from "../../authContext"; 
import useStore from '../../store/store';

const CustomersDropdown = (props) =>
{
  const context = useContext(AuthContext);
  const initState = {
    toggle: false,
    customer: "",
  }

  const [fields, setFields] = useState(initState);
  const [globalState, dispatch] = useStore();

  const onChange = (value, type) =>
  {
    if (type === "customer")
		{
      props.customerChangeHandler(value);
      setFields(prev => ({ ...prev, "customer": value }));
			return;
		};

    setFields(prev => ({
      ...prev,
      [type]: value,
    }))
  }

  return (
    <div className={[styles.container, props.className].join(" ")}>
      <div className={styles.content} onClick={() => onChange(!fields.toggle, "toggle")}>
        <span>{fields?.customer?.customer?.firstName || "Customer"} {fields?.customer?.customer?.lastName}</span>
        <i className={fields?.toggle ? styles.rotate : ""}><IoIosArrowDown /></i>
      </div>

      <div className={fields.toggle ? [styles.dropdownContent, props.dropdownContentClassName].join(" ") : styles.dropdownContentHide}>
        <ul>
          {
            globalState.customers.map((value, index) => (
              <li key={index} onClick={() => {
                onChange(value, "customer");
                onChange(!fields.toggle, "toggle");
              }}>{value.customer.firstName} {value.customer.lastName}</li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default CustomersDropdown;