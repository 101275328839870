import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from './style.module.css'
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import languages from "../../localization";

const OpenNews = (props) =>
{
    const {id} = useParams();
    const navigate = useNavigate()
    const [globalState] = useStore();
    const {news} = globalState;
    const [nws, setNws] = useState({});
    useEffect(() => {
        if(isNaN(Number.parseInt(id)))
            navigate("/news", {replace: true});
        
        const findNews = news.find(per => per.id == id);

        if(!findNews)
            navigate("/news", {replace: true});
        window.scrollTo({top: 0, behavior: "smooth"})
        setNws(findNews);

    }, [id]);
    return (
        <main>
            <header className="site-header d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                            <h1 className="text-white">{languages.news_detail}</h1>
                        </div>

                    </div>
                </div>
            </header>


            <section className="shop-detail-section section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-12 m-auto">
                            <div className="custom-block shop-detail-custom-block">
                                <h3 className="mb-3">{nws.title}</h3>

                                <p>{nws.description}</p>

                            </div>
                        </div>

                        <div className="col-lg-6 col-12">
                            <div className="shop-image-wrap">
                                <img src={serverPath(nws.photo || "")} className="shop-image img-fluid" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </main>
    )
}


export default OpenNews;