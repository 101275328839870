import React, { useState } from 'react';
import styles from "./style.module.css";
import languages from '../../localization';
import { useEffect } from 'react';
import useStore from '../../store/store';

const PaymentModal = (props) =>
{
	const [globalState] = useStore();
	const [data, setData] = useState("");

	useEffect(() =>
	{
		if (props.id)
		{
			const findPay = globalState?.payments.find(pay => pay.id === props?.id);
			setData(findPay);
		}
	}, [props?.id]);


	if (!props.visible)
		return;

    return (
		<>
			{props.visible && (
				<div className={[styles.container, "modal show "].join(" ")} style={{ display: 'block' }} tabIndex="-1">
					<div className="modal-dialog justify-content-center">
						<div className="modal-content m-auto">
							<div className="p-3">
								<p>Payment Info</p>
								<div className="row">
									<div>
										<span className={data?.type ? "text-success" : "text-danger"}>{languages.customerName}: </span>
										<span className={data?.type ? "text-success" : "text-danger"}>{[data?.customer?.customer?.firstName, data?.customer?.customer?.lastName].join(" ")}</span>
									</div>
									<div>
										<span className={data?.type ? "text-success" : "text-danger"}>{languages.amount}: </span>
										<span className={data?.type ? "text-success" : "text-danger"}>{data?.amount}</span>
									</div>
									<div>
										<span className={data?.type ? "text-success" : "text-danger"}>{languages.currency}: </span>
										<span className={data?.type ? "text-success" : "text-danger"}>{data?.currencyCode}</span>
									</div>
									<div>
										<span className={data?.type ? "text-success" : "text-danger"}>{languages.dateTime}: </span>
										<span className={data?.type ? "text-success" : "text-danger"}>{new Date(data?.dateTime).toLocaleDateString()}</span>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={props.cancel}>Dismiss</button>
								<button type="button" className={"btn btn-danger col-3"} onClick={() => props.deletehandler(props.id)}>Delete</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
    )
};

export default PaymentModal;