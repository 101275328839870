import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import languages from "../../localization";
import CurrencyDropdown from "../CurrencyDropdown";
import useStore from "../../store/store";
import { AuthContext } from "../../authContext";

const Currency = () =>
{
	const initState = {
		currencies: "",
	};
	
	const [ fields, setFields ] = useState(initState);
	const context = useContext(AuthContext);
	const [ globalState, dispatch ] = useStore();

	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	useEffect(() =>
	{
		(() =>
		{
			if (globalState.currencies.length >= 1)
				return onChange(globalState.currencies, "currency");
		})();
	}, [globalState.currencies]);

	const currencyChangeHandler = (currency) =>
	{
		context.setAuth(prev => ({...prev, currency: currency}));

		dispatch("setData", {type: 'customers', data: []});
		dispatch("setData", {type: 'oppositeCustomers', data: []});
		dispatch("setData", {type: 'transactions', data: []});
		dispatch("setData", {type: 'oppositeTransactions', data: []});
		dispatch("setData", {type: 'dailyTransactions', data: []});
		dispatch("setData", {type: 'selfCash', data: []});
	}

	return (
		<div>

			<p>{languages.by_changing}</p>
			<CurrencyDropdown currencyChangeHandler={(currency) => currencyChangeHandler(currency)} />

			{/* <div className="text-center mt-5">
				<p>No Currency Avaiable</p>
			</div> */}
		</div>
	)
};

export default Currency;