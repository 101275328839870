import React, {useEffect, useContext, useState} from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import languages from "../../localization";
import { useNavigate } from "react-router-dom";
import serverPath from "../../utils/serverPath";
import SweetAlert from '../../Components/SweetAlert';
import validator from '../../validators/customer'
import fetchApi from "../../utils/fetchApi";
const Customer = (props) =>
{
    const navigate = useNavigate()
    const {user, customer, setAuth} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const initFields = {
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    };

    const [fields, setFields] = useState({...initFields});

    useEffect(() => {
      (async() => {
        try {
          if(!user)
            return navigate("/", {replace: true});
          if(customer)
            return navigate("/accounts", {replace: true})
          if(!customer)
          {
            setIsLoading(true);
            const response = await fetch(serverPath("/khata/customer"), {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
                "Access-Control-Allow-Credentials": true
              }
            })

            const isCustomerExist = await response.json();
            if(isCustomerExist.status == 'success')
              if(isCustomerExist.data)
              {
                setAuth(prev => ({...prev, customer: isCustomerExist.data}))
                return navigate("/accounts", {replace: true})
              }

          }
        } catch (error) {
          console.log(error.message);
        }
        setIsLoading(false);
      })()
        
    }, [])


    const onChange = (value, type) => {
      if(type === 'phone')
        return setFields(prev => ({...prev, [type]: value?.replace(/\D/ig, "")}))
      setFields(prev => ({...prev, [type]: value}))
    }
    // /^(07[94031]\d{7})$/

    const onSubmit = async(e) =>
    {
      e.preventDefault()
      try {
        if(!user)
          return navigate("/", {replace: true});
        const {message} = validator(fields);

        if(message)
          return SweetAlert("warning", message);

        if(!fields.phone.match(/^(07[94031]\d{7})$/))
          return SweetAlert("info", 'Invalid Phone Number Entered');

        if(!customer)
        {
          setIsLoading(true);
          const response = await fetchApi({
            path: "/khata/customer",
            method: "POST",
            body: {...fields, type: "own"}
          })

          const isCustomerExist = await response.json();
          if(isCustomerExist.status == 'success')
            if(isCustomerExist.data)
            {
              setAuth(prev => ({...prev, customer: isCustomerExist.data}))
              return SweetAlert("success", "Account Successfully created").then( () => navigate("/khata", {replace: true}))
            }
          if(isCustomerExist.status == 'failure')
            SweetAlert('info', isCustomerExist.message)

        }
      } catch (error) {
        SweetAlert("warning", error.message)
      }
      setIsLoading(false);
    }

  return (
    <main className="container pt-5">
      <section className="section" style={{maxWidth: 760, display: "grid", margin: "50px auto"}}>
        {
          isLoading ?
          <p>Please Wait Until Loading...</p>
          :
          <form className="mt-5" onSubmit={onSubmit}>
            <div className="row mb-3">
              <div className="form-group col-md-6">
                <label htmlFor="firstname">{languages.first_name} *</label>
                <input type="text" className="form-control" id="firstname" placeholder={`${languages.first_name}`} required value={fields.firstName} onChange={({target})=>onChange(target.value, "firstName")} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lastname">{languages.last_name}</label>
                <input type="text" className="form-control" id="lastname" placeholder={languages.last_name}  value={fields.lastName} onChange={({target})=>onChange(target.value, "lastName")} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">{languages.email_address}</label>
                <input type="email" className="form-control" id="inputEmail4" placeholder={languages.email_address}  value={fields.email} onChange={({target})=>onChange(target.value, "email")} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="phone">{languages.phone} *</label>
                <input type="text" className="form-control" id="phone" placeholder={languages.phone} required value={fields.phone} min={10} max={10} onChange={({target})=>onChange(target.value, "phone")} />
              </div>
            </div>
            <button type="submit" className="btn btn-danger">Create Account</button>
          </form>
        }
      </section>
    </main>
  )
}



export default Customer;