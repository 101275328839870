import React, {useEffect, useState} from "react";
import styles from './style.module.css';
import useStore from "../../store/store";
import serverPath from '../../utils/serverPath';
import SweetAlert from "../../Components/SweetAlert";
import { NavLink } from "react-router-dom";
import languages from "../../localization";
import swal from 'sweetalert'


const Home = (props) =>
{

    const [globalState, dispatch] = useStore();
    const [ticket, setTicket] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const {products, sliders, news, exchanges, tokens, invalidTokens} = globalState;

    useEffect(() =>{
        if(sliders.length >= 1)
            window.looping(sliders.map(slider => serverPath(slider.imagePath)));
    }, [sliders])

    const changeHandler = (e) =>
    {
        // setTicket((prev) => e.target.value?.replace(/[\D]/ig, ""))
        setTicket((prev) => e.target.value)
    }
    const submitHandler = async (e) =>
    {
        e.preventDefault();

        if(isLoading)
            return;

        if(ticket.length <= 0)
            return SweetAlert("info", languages.fill_out_ticket_input);
        if(ticket.length !== 8 || ticket.search(/[\D]/ig) >= 0)
            return SweetAlert("info", languages.entered_invalid_ticket);

        const findToken = tokens.find(perToken => perToken.token == ticket)
        if(findToken?.token)
        {
            setTicket("");

            if(!findToken.giftId || !findToken.gifted || findToken.giftTaken)
                return SweetAlert("success", languages.valid_ticket);
            giftAlert(findToken)
            return
        }
        if(!findToken?.token){
            const findInvalidToken = invalidTokens.find(perToken => perToken.token == ticket);
            if(findInvalidToken?.token)
            {
                setTicket("");
                return SweetAlert("warning", languages.entered_invalid_ticket);
            }
        }

        try {
            setIsLoading(true);
            const response = await fetch(serverPath('/token/validation'), {
                method: "post",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({token: ticket})
            });
            setIsLoading(false);
            if(response.status === 429)
            {
                console.clear();
                setTicket("");
                SweetAlert("error", "You Tried Too Many Tokens Please Try Again Later").then(() => window.location.reload())
                return 
            }
            const objData = await response.json();
            if(objData.status === "success")
            {
                setTicket("");
                dispatch("setData", {type: 'tokens', data: [...tokens, objData.data]});

                if(!objData?.data.giftId || !objData?.data.gifted || objData?.data.giftTaken)
                    return SweetAlert("success", languages.valid_ticket);
                giftAlert(objData?.data)
                return
            }
            if(objData.status === "failure")
            {
                dispatch("setData", {type: 'invalidTokens', data: [...invalidTokens, {token: ticket}]});
                setTicket("");
                return SweetAlert("warning", languages.entered_invalid_ticket);
            }
            return SweetAlert("warning", languages.try_again);
        } catch (error) {
            setTicket("");
            setIsLoading(false);
            console.log(error?.message)
            return SweetAlert("error", languages.try_again);
        }

    }



async function giftAlert(data) {
    const Paragraph = document.createElement("p");
    Paragraph.setAttribute("style", `direction:${languages.isRTL ? "rtl": "ltr"}`)
    Paragraph.insertAdjacentHTML("afterbegin", languages.congratulation_letters(data?.gift?.name))
    return await swal({
        dangerMode: true,
        title: languages.congratulations,
        content: {element:  Paragraph},
        icon: serverPath(data?.gift.photo),
        });
}
    
  const defaultState = {
    firstName: "",
    lastName: "",
    email: "",
    message: ""
  }
  const [contactFields, setContactFields] = useState({...defaultState});

  const contactChangeHandler = (type, value) => setContactFields(prev => ({...prev, [type]: value}));


  const contactHandler = async (e) =>
  {
    e.preventDefault()
    const  emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; ;

    for (const key in contactFields) {
      if(key === "email" && (!emailPattern.test(contactFields[key])))
        return SweetAlert("error", languages.entere_correct_email);
      if(contactFields[key].length < 3)
        return SweetAlert("error", languages.all_inputs_are_requred);
    }

    try {
      
      
      const response = await fetch(serverPath("/contact"), {
        method: "POST",
        headers: {
          "Content-Type": "Application/json"
        },
        body: JSON.stringify(contactFields)
      });

      const objData = await response.json();

      if (objData.status === "failure") {
        return SweetAlert("error", objData.error.message);
      }
      SweetAlert("success", languages.we_will_contact_you);

      setContactFields({...defaultState});

    } catch (error) {
        console.log(error.message)
        return SweetAlert("error", languages.try_again);
    }
    
  }
    
  return (
    <>
<main>
<section className="hero-section hero-slide d-flex justify-content-center align-items-center" id="section_1">
    <div className="container">
        <div className="row">

            <div className="col-lg-8 col-12 text-center mx-auto">
                <div className="hero-section-text">
                    <form className="custom-form hero-form" role="form" onSubmit={submitHandler}>
                        <div className="row">
                            <div className="col-lg-9 col-12">
                                {/* <div className="input-group align-items-center">
                                    <label htmlFor="product-name">{languages.ticket}</label>
                                    <input type="text" name="product-name" id="product-name" className="form-control" placeholder={languages.check_ticket} required value={ticket} onChange={changeHandler} disabled={isLoading} />
                                </div> */}
                                <div className="input-group align-items-center">
                                    {/* <label htmlFor="product-name">{languages.ticket}</label> */}
                                    <input type="text" name="product-name" id="product-name" className="form-control" placeholder={languages.write_something} required value={ticket} onChange={changeHandler} disabled={isLoading} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-12">
                                {/* <button type="submit" className={[`form-control btn ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading}>{!isLoading ? languages.check : languages.please_wait}</button> */}
                                <button type="submit" className={[`form-control btn ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={true}>{!isLoading ? languages.search : languages.please_wait}</button>
                            </div>
                        </div>
                    </form>

                    <div className="hero-btn d-flex justify-content-center align-items-center">
                        <a className="bi-arrow-down hero-btn-link smoothscroll" href="#section_2"></a>
                    </div> 
                </div>
            </div>

        </div>
    </div>
</section>


{/* <section className="reviews-section about-section section-padding" id="section_2">
    <div className="container">
        <div className="row">

            <div className="col-lg-12 col-12">
                <small className="section-small-title">{languages.targeting}.</small>

                <h2 className="mt-2 mb-4">{languages.products}</h2>

                <div className={`owl-carousel reviews-carousel ${languages.isRTL ? "carousel-rtl": ""}`} style={{direction: "ltr", display: "block"}}>
                    {products.map((product, index) => {
                        const target = (product.tokenCount / product.target) * 100; 
                    return(
                    <div className="reviews-thumb" key={(product.id + "" + index)}>
                        <div className="reviews-body" style={{backgroundImage: `url(${serverPath(product.photo)})`, backgroundSize: "cover", backgroundPosition: "center", height: 250, backgroundRepeat: "no-repeat"}}>
                        </div>
                        <div className="reviews-bottom reviews-bottom-up d-flex align-items-center">
                            <img src={serverPath(product.photo)} className="avatar-image img-fluid" alt="" />

                            <div className="d-flex align-items-center justify-content-between flex-wrap w-100 ms-3">
                                <p className="text-white mb-0">
                                    <strong>{product.name}</strong>
                                </p>
                                <div className="reviews-icons text-white">
                                    <small>{languages.target}</small> <strong>{(target < 100 ? target.toFixed(2) : 100)}%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
            </div>

        </div>
    </div>
</section> */}
{ (exchanges.length > 0) &&
<section className="reviews-section about-section section-padding" id="section_2">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-12">
                <small className="section-small-title">{languages.currencies}.</small>

                <h2 className="mt-2 mb-4">{languages.exchange_rates}</h2>

                <div className="row" style={{direction: "ltr"}}>
                    {
                    exchanges.map(currency => {
                        let subCurrencies = currency.subCurrencies;
                        if (typeof(subCurrencies) === "string") {
                            subCurrencies = JSON.parse(subCurrencies)

                            if (typeof(subCurrencies) === "string")
                                subCurrencies = JSON.parse(subCurrencies)
                        }
                        return (
                        <div className={["col-12 col-md-6 col-lg-4 mb-4"].join(' ')} key={currency.id}>
                            <div className={[styles.currencyCard].join(" ")}>
                                <div className="row">
                                    <h6 className={["col-6", currency.vector ? "text-info" : "text-danger"].join(" ")}>
                                        {currency.mainName} 
                                        <i className={["bi", currency.vector ? "bi-arrow-up" : "bi-arrow-down"].join(" ")}></i>
                                    </h6>
                                </div>
                                {subCurrencies.map((perSub, index) => (
                                <div className="row" key={index}>
                                    <div className="col-5">
                                        <p>{currency.amount} : {currency.mainName}</p>
                                    </div>
                                    <div className="col-2">
                                        <p>=</p>
                                    </div>
                                    <div className="col-5">
                                        <p>{perSub.amount} : {perSub.name}</p>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    )})
                    }
                </div>
            </div>
        </div>
    </div>
</section>
}

<section className="shop-section section-padding" id="section_2">
    <div className="container">
        <div className="row">

            <div className="col-lg-12 col-12">
                {/* <small className="section-small-title">{languages.millonar}</small> */}
                <small className="section-small-title">{languages.brainbbox}</small>

                <h2 className="mt-2 mb-4"><span className="tooplate-red">{languages.latest}</span> {languages.news}</h2>
            </div>
            {([...news].reverse().slice(0, 3)).map(nws => (
            <div className="col-12 col-md-6 col-lg-4" key={nws.id}>
                <div className="shop-thumb" >
                    <div className="shop-image-wrap">
                        <NavLink to={`/news/${nws.id}`}>
                            <img src={serverPath(nws.photo)} className="shop-image img-fluid" alt="image" />
                        </NavLink>

                        <div className="shop-btn-wrap">
                            <NavLink to={`/news/${nws.id}`} className="shop-btn custom-btn btn d-flex align-items-center align-items-center">{languages.learn_more}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            ))}
        </div>
    </div>
</section>

<section className="reviews-section section-padding pb-0" id="section_3">
    <div className="container">
        <div className="row align-items-center">

            <div className="col-lg-5 col-12">
                <small className="section-small-title">{languages.our_story}</small>

                {/* <h2 className="mt-2 mb-4"><span className="text-muted">{languages.introducing}</span> {languages.millonar}</h2> */}
                <h2 className="mt-2 mb-4"><span className="text-muted">{languages.introducing}</span> {languages.brainbbox}</h2>

                {/* <h4 className="text-muted mb-3">
                    {languages.millonar_story}
                </h4> */}
                <h4 className="text-muted mb-3">
                    {languages.brainbbox_story}
                </h4>

            </div>

            <div className="col-lg-3 col-md-5 col-5 mx-lg-auto">
                <img src="images/sharing-design-ideas-with-family.jpg" className="about-image about-image-small img-fluid" alt="" />
            </div>

            <div className="col-lg-4 col-md-7 col-7">
                <img src="images/living-room-interior-wall-mockup-warm-tones-with-leather-sofa-which-is-kitchen-3d-rendering.jpg" className="about-image img-fluid" alt="" />
            </div>

        </div>
    </div>
</section>


<section className="contact-section" id="section_4">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f9f9f9" fillOpacity="1" d="M0,96L40,117.3C80,139,160,181,240,186.7C320,192,400,160,480,149.3C560,139,640,149,720,176C800,203,880,245,960,250.7C1040,256,1120,224,1200,229.3C1280,235,1360,277,1400,298.7L1440,320L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>
    <div className="container">
        <div className="row">

            <div className="col-lg-12 col-12">
                <small className="section-small-title">{languages.ask_any}</small>

                <h2 className="mb-4">{languages.hello}</h2>
            </div>

            <div className="col-lg-6 col-12">
                <form className="custom-form contact-form" role="form" onSubmit={contactHandler}>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="input-group align-items-center">
                                <label htmlFor="first-name">{languages.first_name}</label>

                                <input type="text" name="first-name" id="first-name" className="form-control" placeholder={languages.ahmad} value={contactFields.firstName} onChange={({target}) => contactChangeHandler("firstName", target.value)} required />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="input-group align-items-center">
                                <label htmlFor="last-name">{languages.last_name}</label>

                                <input type="text" name="last-name" id="last-name" className="form-control" placeholder={languages.khan} value={contactFields.lastName} onChange={({target}) => contactChangeHandler("lastName", target.value)} required />
                            </div>
                        </div>
                    </div>

                    <div className="input-group align-items-center">
                        <label htmlFor="email">{languages.email_address}</label>

                         <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder={languages.email_address} value={contactFields.email} onChange={({target}) => contactChangeHandler("email", target.value)} required />
                    </div>

                    <div className="input-group textarea-group">
                        <label htmlFor="message">{languages.message}</label>

                        <textarea name="message" rows="6" className="form-control" id="message" placeholder={languages.how_can_we_help_you} value={contactFields.message} onChange={({target}) => contactChangeHandler("message", target.value)}></textarea>
                    </div>

                    <div className="col-lg-3 col-md-4 col-6">
                        <button type="submit" className="form-control">{languages.send}</button>
                    </div>
                </form>
            </div>

            <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                <div className="custom-block">

                    <h3 className="text-white mb-2">{languages.address}</h3>

                    <p className="text-white mb-2">
                        <i className="contact-icon bi-geo-alt me-1"></i>
                        {languages.office_address}
                    </p>

                    <h3 className="text-white mt-3 mb-2">{languages.contact_info}</h3>

                    <div className="d-flex flex-wrap">
                        <p className="text-white mb-2 me-4">
                            <i className="contact-icon bi-telephone me-1"></i>

                            <a href="tel:+93788622351" className="text-white">
                                {languages.mobile_number}
                            </a>
                        </p>

                        <p className="text-white">
                            <i className="contact-icon bi-envelope me-1"></i>

                            <a href="mailto:info@brainbbox.com" className="text-white">
                                info@brainbbox.com
                            </a>
                            {/* <a href="mailto:info@millonar.com" className="text-white">
                                info@millonar.com
                            </a> */}
                        </p>
                    </div>

                    <iframe className="google-map mt-2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d210303.1819740574!2d69.03292375159467!3d34.55387900748457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d1694c3c1e6d49%3A0xebdf473578214429!2sKabul%2C%20Afghanistan!5e0!3m2!1sde!2s!4v1699948086101!5m2!1sde!2s" width="100%" height="220" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
                </div>
            </div>

        </div>
    </div>
</section>

</main>
    </>
  )
}



export default Home;