import React, {useEffect, useContext, useState} from "react";
import styles from './style.module.css';
import useStore from "../../store/store";
import { AuthContext } from "../../authContext";
import languages from "../../localization";
import { useNavigate } from "react-router-dom";
import DRAW from '../../Assets/draw.jpg';
import DRAW_BG from '../../Assets/drawbg.jpg';
const Draw = (props) =>
{
    const [globalState] = useStore();
    const {drawdates, drawtokens} = globalState;
    const navigate = useNavigate()
    const context = useContext(AuthContext);
    const[countDown, setCountDown] = useState({
        expired: true,
        date: ""
    });
    const initAnimateLucky  = {
        duration: 50,
        tokens: drawtokens,
        isStarted: drawdates.length <= 0 ? false : true,
        spinning: drawdates.length <= 0 ? false : true,
        selectedTokens: []
      }
      
    const [animateLucky, setAnimateLucky] = useState({...initAnimateLucky});
    useEffect(() => {
        if(drawdates.length <= 0 || (!context.user))
            return navigate('/', {replace: true})
            
        let drawDate = new Date(drawdates[0].drawDate);
        let countdownInterval = 1;
            countdownInterval = setInterval(()=>updateCountdown(drawDate.getTime(), countdownInterval), 1000);

            

        window.scrollTo({top: 0, behavior: "smooth"});
        let footer = document.querySelector("footer");
        let fter = document.querySelector(".fter");
        footer.style.display = "none"
        document.body.style.overflowX = "hidden";
        fter.style.display = "none"
        return () =>
        {
            document.body.style.overflowX = "auto";
            footer.style.display = "block"
            fter.style.display = "inline"
        }
    }, [])

    function updateCountdown(targetTime, countdownInterval) {
        const now = new Date().getTime();
        const timeDifference = targetTime - now;

        if (timeDifference <= 0) {
            clearInterval(countdownInterval);
            setCountDown(prev => ({...prev, expired: true}))
        } else {
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
            setCountDown(prev => ({...prev, date: `${days}d ${hours}h ${minutes}m ${seconds}s`, expired: true}))
        }
    }

  return drawdates.length <= 0 ? null : (
    <main>
      <section className="section" >
        <div className="row m-0">
          <div className="col-12">
            <div className="card" style={{backgroundImage: `url(${DRAW_BG})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
              <div className={[styles.text, 'm-auto'].join(" ")}>
              {countDown.expired &&
                <div className={["mt-4", styles.time].join(' ')}>
                    <p>
                       TIME:  {countDown.date}
                    </p>
                </div>
                }
                <div className={styles.drawImg}>
                  <img src={DRAW} style={{borderRadius: "50%"}} />
                  <div className={[styles.animatingTokens, styles.scalling].join(" ")}>
                    <div className={animateLucky.spinning ? styles.top : ""} style={{animationDuration: `${animateLucky.duration}s`}}>
                      {animateLucky.isStarted ? animateLucky.tokens.map((per, index) => (
                        <p key={index}>{per.token}</p>
                      )): <p>T.O.K.E.N</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}



export default Draw;