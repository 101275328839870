import React, {useEffect, useContext, useState} from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import languages from "../../localization";
import { useNavigate } from "react-router-dom";
import serverPath from "../../utils/serverPath";
import SweetAlert from '../../Components/SweetAlert';
import fetchApi from '../../utils/fetchApi'
import useStore from "../../store/store";
const Accounts = (props) =>
{

    const [globalState, dispatch] = useStore();
    const {cashbooks} = globalState;
    const navigate = useNavigate()
    const {user, customer, setAuth} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      (async() => {
        try {
          
          if(cashbooks?.length > 0)
            return;
            setIsLoading(true);
            const response = await fetchApi({
              path: "/khata/get/cashbook",
              method: "POST",
              body: {ownerId: customer.id}
            })

            const objData = await response.json();
            if(objData.status === 'success')
              dispatch('setData', {type: 'cashbooks', data: objData.data})
            if(objData.status === 'failure')
              SweetAlert("warning", objData.message)
          
        } catch (error) {
          console.log(error.message)
        }
        setIsLoading(false);
      })()
    }, [])


    const onView = async(id) =>
    {

    }

  return (
    <main className="container pt-5">
      <section className="section" style={{maxWidth: 760, display: "grid", margin: "50px auto"}}>
        {
          isLoading ?
          <p>Please Wait Until Loading...</p>
          :
          <div className="wrapper">
            <button onClick={() => navigate("/new/customer")} className="btn btn-danger">
              Add Customer
            </button>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
              {cashbooks.length > 0 ?
              cashbooks.map((cashBook, index) => {
                const customer = cashBook.customer;
                return (
                <tr key={cashBook.id}>
                  <th scope="row">{(index + 1)}</th>
                  <td>{customer.firstName}</td>
                  <td>{customer.lastName}</td>
                  <td>{customer.phone}</td>
                  <td>{customer.email}</td>
                  <td>
                    <button className="btn btn-danger">
                      View/Edit
                    </button>
                  </td>
                </tr>
                )
              })
              :
                <tr>
                  <th className="text-center" scope="row" colSpan={6}>No Customer Account Found</th>
                </tr>
              }
              </tbody>
            </table>
          </div>

        }
      </section>
    </main>
  )
}



export default Accounts;