import LocalizedStrings from 'react-localization';

let languages = new LocalizedStrings({
  en:{
    isRTL: false,
    millonar: "Millonar",
    brainbbox: "Brainbbox",
    home: "Home",
    products: "Products",
    news: "News",
    about: "About",
    contact: "Contact",
    login: "Login",
    profile: "Profile",
    logout: "Logout",
    you_can_login_or_logout: "You can log in to an already existing account or create a new one. Just click one of the buttons below.",
    info: "Info",
    warning: "Warning",
    error: "Error",
    success: "Success",
    fill_out_ticket_input: "Please Fill Out The Ticket Input",
    entered_invalid_ticket: "You Entered Invalid Ticket",
    valid_ticket: "Your Ticket Is Valid And Registered In Our System",
    try_again: "Please Try Again",
    entere_correct_email: "Please Enter Correct Email Address!",
    all_inputs_are_requred: "All the contacts fields are requierd and at least must be 3 character long!",
    we_will_contact_you: "Thank You! \n An expert from Contact Us will contact you shortly.",
    ticket: "Ticket",
    check_ticket: "Check Your Ticket",
    please_wait: "Please Wait.",
    check: "Check",
    write_something: "Write Something",
    search: "Search",
    target: "Target",
    targeting: "Targeting",
    latest: "Latest",
    learn_more: "Learn more",
    our_story: "Our Story",
    introducing: "Introducing",
    millonar_story: "Millionaire is the world's first program that turns dreams into reality. We are dedicated to ensuring the happiness of our customers.",
    brainbbox_story: "Brainbbox is the world's first program that turns dreams into reality. We are dedicated to ensuring the happiness of our customers.",
    ask_any: "Ask anything",
    hello: "Say Hello",
    first_name: "First Name",
    last_name: "Last Name",
    ahmad: "Ahmad",
    khan: "Khan",
    email_address: "Email Address",
    message: "Message",
    how_can_we_help_you: "What can we help you?",
    send: "Send",
    address: "Address",
    office_address: "Head office Kabul Srai Shahzada",
    contact_info: "Contact Info",
    mobile_number: "937-886-223-51",
    welcome_to_news: "Welcome To News Page",
    your_profile: "Your Profile",
    google: "Your Are Logged In From Google Account",
    facebook: "Your Are Logged In From Facebook Account",
    profile_note_from_millonar: `
              You are registered in our system. 
              We have plans to add a variety of services for both our customers and users in the future. 
              These services will be accessible and manageable by every user who is registered with us, 
              allowing them to easily modify and edit their profile information along with utilizing the services provided
              `,
    developers: "Developers",
    jahanmal_team: "Jahanmal Team",
    kabul: "Kabul",
    afghanistan: "Afghanistan",
    copy_right: "Copyright",
    news_detail: "News Detail",
    currencies: "Currencies",
    exchange_rates: "Exchange Rates",
    congratulations: "Congratulations!",
    congratulation_letters: (text)=> (`Your token has been registered in our system, and <b> you've won a gift</b> - a pair of <b class="text-danger">${text}</b>. You can collect your gift at any of our <b>branches</b>.`),
    live_draw: "Live Draw",
    customer_account: "Customer Account",
    phone: "Phone Number",
    customers: "Customers",
    cashbook: "Cashbook",
    currency: "Currency",
    opposite: "Opposite",
    profile: "Profile",
    cashIn: "Cash In",
    cash: "Cash",
    cashOut: "Cash Out",
    dateTime: "Date Time",
    fromDate: "From Date",
    toDate: "To Date",
    customerName: "Customer Name",
    amount: "Amount",
    type: "Type",
    by_changing: "By changing the currency all data well change to that currency!",
    payment: "Payment",
  },
  ps:
  {
    isRTL: true,
    millonar: "میلونر",
    brainbbox: "براين بوکس",
    home: "کور",
    products: "محصولات",
    news: "خبرونه",
    news_detail: "خبر اړوند",
    about: "په اړه",
    contact: "اړیکه",
    login: "ننوتل",
    profile: "پروفایل",
    logout: "وتل",
    you_can_login_or_logout: "تاسو کولی شئ د پخوا څخه موجود حساب ته ننوځئ یا یو نوی جوړ کړئ. یوازې لاندې تڼۍ کلیک وکړئ.",
    info: "معلومات",
    warning: "خبرداری",
    error: "تېروتنه",
    success: "بریالیتوب",
    fill_out_ticket_input: "مهرباني وکړئ د ټکټ ان پټ ډک کړئ",
    entered_invalid_ticket: "تاسو ناسم ټکټ داخل کړی",
    valid_ticket: "ستاسو ټیکټ صحیح دی او زموږ په سیسټم کې ثبت شوی",
    try_again: "مهرباني وکړئ بیا هڅه وکړئ",
    entere_correct_email: "مهرباني وکړئ سم بریښنالیک آدرس دننه کړئ",
    all_inputs_are_requred: "د اړیکو ټولې ساحې اړینې دي او لږترلږه باید د 3 حروف اوږد وي",
    we_will_contact_you: "له تاسو مننه \n زموږ سره د تماس یو متخصص به ډیر ژر له تاسو سره اړیکه ونیسي.",
    ticket: "ټکټ",
    check_ticket: "خپل ټکټ وګورئ",
    please_wait: "هیله کوم لږ صبر وکړه",
    check: "چیک",
    write_something: "یو څه ولیکئ",
    search: "لټون",
    target: "هدف",
    targeting: "په نښه کول",
    latest: "وروستی",
    learn_more: "نور زده کړئ",
    our_story: "زموږ کیسه",
    introducing: "معرفي کول",
    millonar_story: "میلیونر په نړۍ کې لومړنی پروګرام دی کوم چې خوبونه په حقیقت بدلوي. موږ د خپلو پیرودونکو د خوښۍ ډاډ ترلاسه کولو ته ژمن یو.",
    brainbbox_story: "براين بوکس په نړۍ کې لومړنی پروګرام دی کوم چې خوبونه په حقیقت بدلوي. موږ د خپلو پیرودونکو د خوښۍ ډاډ ترلاسه کولو ته ژمن یو.",
    ask_any: "هر څه وپوښتئ",
    hello: "سلام ووایه",
    first_name: "لومړی نوم",
    last_name: "وروستی نوم",
    ahmad: "احمد",
    khan: "خان",
    email_address: "برېښليک پته",
    message: "پیغام",
    how_can_we_help_you: "موږ ستاسو سره څه مرسته کولی شو؟",
    send: "لیږل",
    address: "پته",
    office_address: "مرکزي دفتر کابل سرای شهزاده",
    contact_info: "د اړیکو معلومات",
    mobile_number: "937-886-223-51",
    welcome_to_news: "خبر پاڼې ته ښه راغلاست",
    your_profile: "ستاسو پروفایل",
    google: "ستاسو د ګوګل حساب څخه ننوتلی یاست",
    facebook: "ستاسو د فیسبوک حساب څخه ننوتلی یاست",
    profile_note_from_millonar: `
    تاسو زموږ په سیسټم کې ثبت شوي یاست.
    موږ پلان لرو چې په راتلونکي کې زموږ د پیرودونکو او کاروونکو دواړو لپاره مختلف خدمات اضافه کړو.
    دا خدمتونه به د هر هغه کارونکي لخوا د لاسرسي وړ او اداره شي چې زموږ سره راجستر وي،
    دوی ته اجازه ورکوي چې د چمتو شوي خدماتو کارولو سره د دوی پروفایل معلومات په اسانۍ سره ترمیم او ترمیم کړي
              `,
    developers: "ډیویلفران",
    jahanmal_team: "جهان مل ټیم",
    kabul: "کابل",
    afghanistan: "افغانستان",
    copy_right: "کاپی رایټ",
    currencies: "اسعار",
    exchange_rates: "د اسعارو تبادل",
    congratulations: "مبارکيانی",
    congratulation_letters: (text)=> (`ستاسو ټکیټ زموږ په سیسټم کې ثبت شوې دی، او <b>تاسو ډالۍ ګټلې</b> - یو جوړه <b class="text-danger">${text}</b>. تاسو کولی شئ خپل ډالۍ زموږ له هرې <b>څانګی</b> څخه ترلاسه کړی.`),
    live_draw: "ژوندی بڼه پچه اچونه",
    customer_account: "پیرودونکی حساب",
    phone: "د تلیفون شمیره",
    customers: "پیرودونکي",
    cashbook: "د نغدو پیسو کتاب",
    currency: "اسعار",
    opposite: "مخالف",
    profile: "پروفایل",
    cashIn: "راوړل سوي",
    cash: "نغدې",
    cashOut: "وړل سوي",
    dateTime: "وخت",
    fromDate: "له نیټې څخه",
    toDate: "تر نیټې پورې",
    customerName: "د پیرودونکي نوم",
    amount: "مقدار",
    type: "معامله",
    by_changing: "د اسعارو په بدلولو سره ټول معلومات په دې اسعارو بدلیږي!",
    payment: "تادیه",
  },
  pa:
  {
    isRTL: true,
    millonar: "میلیونار",
    brainbbox: "براين بوکس",
    home: "خانه",
    products: "محصولات",
    news: "اخبار",
    news_detail: "اخبار",
    about: "درباره",
    contact: "تماس",
    login: "وارد شدن",
    profile: "مشخصات",
    logout: "خروج",
    you_can_login_or_logout: "می توانید به یک حساب کاربری موجود وارد شوید یا یک حساب جدید ایجاد کنید. کافیست یکی از دکمه های زیر را کلیک کنید.",
    info: "اطلاعات",
    warning: "هشدار",
    error: "خطا",
    success: "موفقیت",
    fill_out_ticket_input: "لطفا ورودی بلیط را پر کنید",
    entered_invalid_ticket: "شما بلیط نامعتبر وارد کرده اید",
    valid_ticket: "بلیط شما معتبر و در سیستم ما ثبت شده است",
    try_again: "لطفا دوباره تلاش کنید",
    entere_correct_email: "لطفا آدرس ایمیل صحیح را وارد کنید!",
    all_inputs_are_requred: "تمام فیلدهای مخاطبین مورد نیاز هستند و حداقل باید 3 کاراکتر داشته باشند!",
    we_will_contact_you: "متشکرم! \n یک متخصص از تماس با ما به زودی با شما تماس خواهد گرفت.",
    ticket: "بلیط",
    check_ticket: "بلیط خود را بررسی کنید",
    please_wait: "لطفا صبر کنید.",
    check: "بررسی",
    write_something: "چیزی بنویسید",
    search: "جستجو کردن",
    target: "هدف",
    targeting: "هدف گذاری",
    latest: "Latest",
    learn_more: "بیشتر بدانید",
    our_story: "داستان ما",
    introducing: "معرفی کردن",
    millonar_story: "میلیونر اولین برنامه جهان است که رویاها را به واقعیت تبدیل می کند. ما متعهد به اطمینان از خوشحالی مشتریان خود هستیم.",
    brainbbox_story: "براين بوکس اولین برنامه جهان است که رویاها را به واقعیت تبدیل می کند. ما متعهد به اطمینان از خوشحالی مشتریان خود هستیم.",
    ask_any: "هر چیزی بپرس",
    hello: "سلام برسان",
    first_name: "نام کوچک",
    last_name: "نام خانوادگی",
    ahmad: "احمد",
    khan: "خان",
    email_address: "آدرس ایمیل",
    message: "پیام",
    how_can_we_help_you: "چه کمکی می توانیم به شما کنیم؟",
    send: "ارسال",
    address: "نشانی",
    office_address: "دفتر مرکزی کابل سری شهزاده",
    contact_info: "اطلاعات تماس",
    mobile_number: "937-886-223-51",
    welcome_to_news: "به صفحه اخبار خوش آمدید",
    your_profile: "مشخصات شما",
    google: "شما از حساب ګوګل وارد شده اید",
    facebook: "شما از حساب فیس بوک وارد شده اید",
    profile_note_from_millonar: `
    شما در سیستم ما ثبت نام کرده اید.
    ما برنامه هایی داریم که در آینده خدمات متنوعی را برای مشتریان و کاربران خود اضافه کنیم.
    این خدمات توسط هر کاربری که در ما ثبت نام کرده باشد قابل دسترسی و مدیریت خواهد بود.
    به آنها این امکان را می دهد که به راحتی اطلاعات نمایه خود را همراه با استفاده از خدمات ارائه شده اصلاح و ویرایش کنند
              `,
    developers: "ډیویلفران",
    jahanmal_team: "جهان مل ټیم",
    kabul: "کابل",
    afghanistan: "افغانستان",
    copy_right: "کاپی رایټ",
    currencies: "ارزها",
    exchange_rates: "نرخ تبدیل",
    congratulations: "تبریک می گویم",
    congratulation_letters: (text)=> (`رمز شما در سیستم ما ثبت شده است و <b> شما یک هدیه گرفته اید</b> - یک جفت <b class="text-danger">${text}</b>. می توانید هدیه خود را در هر یک از <b>شعبه</b> ما جمع آوری کنید.`),
    live_draw: "ژوندی بڼه پچه اچونه",
    customer_account: "حساب مشتری",
    phone: "شماره تلفن",
    customers: "مشتریان",
    cashbook: "کتاب نقدی",
    currency: "ارز",
    opposite: "مقابل",
    profile: "نمایه",
    cashIn: "دریافت کرد",
    cash: "پول نقد",
    cashOut: "برده شده",
    dateTime: "وقت",
    fromDate: "از تاریخ",
    toDate: "به تاریخ",
    customerName: "نام مشتری",
    amount: "مقدار",
    type: "معامله",
    by_changing: "با تغییر ارز، تمام داده ها به خوبی به آن ارز تغییر می کنند!",
    payment: "پرداخت",
  },
});



export default languages;