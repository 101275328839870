import React, {useEffect, useContext, useState} from "react";
import styles from './style.module.css';
import useStore from "../../store/store";
import { AuthContext } from "../../authContext";
import languages from "../../localization";
import { useNavigate } from "react-router-dom";
import serverPath, { khataServer } from "../../utils/serverPath";
import Customers from "../../Components/Customers";
import Currency from "../../Components/Currency";
import Opposite from "../../Components/Opposite";
import Cashbook from "../../Components/Cashbook";
import ProfileComponent from "../../Components/ProfileComponent";
import { PieChart } from 'react-minimal-pie-chart';
import PaymentSchedule from "../../Components/PaymentSchedule";
import { useMediaQuery } from "react-responsive";
import Payment from "../../Components/Payment";

const Profile = (props) =>
{
    const [globalState, dispatch] = useStore();
    const navigate = useNavigate()
    const context = useContext(AuthContext);

    const [ pageType, setPageType ] = useState("customers");
    const [ circleData, setCircleData ] = useState([]);

    useEffect(() => {
        if(!context.user)
            return navigate('/', {replace: true})

        window.scrollTo({top: 0, behavior: "smooth"})
    }, []);

    useEffect(() =>
    {
        (async () =>
        {
            if (globalState.circleCash) 
            {
                const colors = ["#0b84a5", "#3c97da", "#6f4e7c"];
                let data = [];

                for (let i = 0; i <= globalState.circleCash.length; i++) {

                    if (globalState.circleCash[i])
                    {
                        const cash = globalState.circleCash[i]?.cashIn - globalState.circleCash[i]?.cashOut;
                        data.push({title: `${globalState.circleCash[i].currencyCode + " " + cash}`, value: cash, color: colors[i], label: "WELCOME"});
                    }
                };
                setCircleData(data);
            }

            if (globalState.payments.length <= 0 && context.customer)
            {
                const response = await fetch(khataServer("/get/payment"), {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/JSON",
                    },
                    body: JSON.stringify({providerId: context.user.id, ownerId: context.customer.id})
                });
                const objData = await response.json();
                if (objData.status === "success" && objData.data.length >= 1)
                {
                    let data = [];
                    objData.data.forEach(pay => {
                        const currencyCode = globalState?.currencies?.find(curr => curr.id === pay.currencyId)?.code;
                        const customer = globalState.customers.find(cust => cust.customerId === pay.customerId);
                        data.push({...pay, currencyCode, customer });
                    });
                    dispatch("setData", {type: 'payments', data});
                }

                return;
            }

            if (globalState.payments.length >= 1)
                dispatch("setData", {type: 'payments', data: globalState.payments});

        })();
    }, [globalState.circleCash, globalState.payments]);



    const submitHandler = (e) =>
    {
        e.preventDefault()
        navigate("/draw")
    }

    const onCustomerAccount = (e) => {
        e.preventDefault();
        navigate("/customer")
    }
    
    const createCustomerAcc = async () =>
    {
        try {

            const response = await fetch(serverPath("/khata/customer"), {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    firstName: "Ahmadullah",
                    lastName: "Khan",
                    phone: "0700319511",
                    email: 'ahmadullah@gmail.com',
                    type: "own"
                })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }
    
    const updateCustomerAcc = async () =>
    {

        try {

            const response = await fetch(serverPath("/khata/customer"), {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    firstName: "Khalid",
                    lastName: "Khan",
                    phone: "0744488811",
                    email: 'khalid@gmail.com',
                    id: 2
                    
                })
                // body: JSON.stringify({
                //     firstName: "AHMADULLAH",
                //     lastName: "Khan",
                //     phone: "0744488816",
                //     email: "abcd@gmail.com",
                //     id: 11
                    
                // })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }
    
    const deleteCustomerAcc = async () =>
    {

        try {

            const response = await fetch(serverPath("/khata/customer"), {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    id: 2
                })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }
    
    const getCustomerAcc = async () =>
    {

        try {

            const response = await fetch(serverPath("/khata/get/customer"), {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    phone: "0744488811"
                })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }


    // CashIn/Out Hover effect
    // Is Loading
    // Profile
    // Circle
    // Share in PDF
    // Report Currency don't show
    // Payment Schadual
    // Pigiation Table || responsive
    // File Attachment
    
    // Voucher


  return (
    <main>
        <section className="section-padding">
            <div className={["container py-2", styles.content].join(" ")} style={{backgroundColor: 'rgb(249 253 254)'}}>

                { context.customer && <>
                    <div>
                        <nav className="navbar navbar-expand-lg bg-body-tertiary">
                            <div className="container-fluid px-0">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse align-items-start" id="navbarScroll">
                                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                        <li className="nav-item" onClick={() => setPageType("customers")}>
                                            <a
                                                className={["nav-link click-scroll", (pageType === "customers" && "active")].join(" ")} 
                                                href="/profile/#section_customers">
                                                    {languages.customers}
                                                </a>
                                        </li>
                                        <li className="nav-item" onClick={() => setPageType("cashbook")}>
                                            <a
                                                className={["nav-link click-scroll", (pageType === "cashbook" && "active")].join(" ")}
                                                href="/profile/#section_cashbook">
                                                    {languages.cashbook}
                                                </a>
                                        </li>
                                        <li className="nav-item" onClick={() => setPageType("currency")}>
                                            <a
                                                className={["nav-link click-scroll", (pageType === "currency" && "active")].join(" ")}
                                                href="/profile/#section_currency">
                                                    {languages.currency}
                                                </a>
                                        </li>
                                        <li className="nav-item" onClick={() => setPageType("opposite")}>
                                            <a
                                                className={["nav-link click-scroll", (pageType === "opposite" && "active")].join(" ")}
                                                href="/profile/#section_opposite">
                                                    {languages.opposite}
                                                </a>
                                        </li>
                                        <li className="nav-item" onClick={() => setPageType("payment")}>
                                            <a
                                                className={["nav-link click-scroll", (pageType === "payment" && "active")].join(" ")}
                                                href="/profile/#section_payment">
                                                    {languages.payment}
                                                </a>
                                        </li>
                                        <li className="nav-item" onClick={() => setPageType("profile")}>
                                            <a
                                                className={["nav-link click-scroll", (pageType === "profile" && "active")].join(" ")}
                                                href="/profile/#section_profile">
                                                    {languages.profile}
                                                </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        {pageType === "customers" && <Customers id="section_customers" />}
                        {pageType === "cashbook" && <Cashbook />}
                        {pageType === "currency" && <Currency />}
                        {pageType === "opposite" && <Opposite />}
                        {pageType === "payment" && <Payment />}
                        {pageType === "profile" && <ProfileComponent />}
                    </div>

                    <div>
                        <div className={styles.circle}>
                            <PieChart
                                data={circleData}
                                lineWidth={35}
                            />
                        </div>

                        <PaymentSchedule />                       
                    </div>
                </>
                }

                {/* {(globalState.drawdates.length > 0) &&
                <div className="row">
                    <div className="col-lg-3 col-12">
                        <form className="custom-form mb-5" onSubmit={submitHandler}>
                            <button type="submit" className={[`form-control btn`].join(' ')}>{languages.live_draw}</button>
                        </form>
                        <form className="custom-form mb-5" onSubmit={onCustomerAccount}>
                            <button type="submit" className={[`form-control btn`].join(' ')}>{languages.customer_account}</button>
                        </form>
                    </div>
                </div>
                } */}
                {/* <div className="row align-items-center">

                    <div className="col-lg-5 col-12">
                        <small className="section-small-title">{languages.your_profile}</small>

                        <h2 className="mt-2 mb-4"><span className="text-muted">{context?.user?.displayName}</span></h2>

                        <h4 className="text-muted mb-3">{languages[context?.user?.provider?.toLowerCase()]}</h4>
                        <p>
                            {languages.profile_note_from_millonar}
                        </p>
                    </div>

                    <div className="col-lg-3 col-md-5 col-5 mx-lg-auto">
                        <img src={context.user?.image || "/profile.jpg"} style={{width: 96, height: 96}} className="about-image about-image-small img-fluid" alt="" />
                    </div>

                    <div className="col-lg-4 col-md-7 col-7">
                        <img src="images/lottery.jpg" className="about-image img-fluid" alt="" />
                    </div>

                </div> */}
            </div>
        </section>
    </main>
  )
}



export default Profile;