import React, { useState } from 'react';
import styles from "./style.module.css";
import languages from '../../localization';
import serverPath from '../../utils/serverPath';

const TransactionModal = (props) =>
{
	if (!props.visible)
		return;

    return (
		<>
			{props.visible && (
				<div className={[styles.container, "modal show "].join(" ")} style={{ display: 'block' }} tabIndex="-1">
					<div className="modal-dialog justify-content-center">
						<div className="modal-content m-auto">
							<div className="p-3">
								<p>Transaction Info</p>
								<div className="row">
									<div>
										<span className={props.data.type ? "text-success" : "text-danger"}>{languages.customerName}: </span>
										<span className={props.data.type ? "text-success" : "text-danger"}>{props.data.customerName}</span>
									</div>
									<div>
										<span className={props.data.type ? "text-success" : "text-danger"}>{languages.amount}: </span>
										<span className={props.data.type ? "text-success" : "text-danger"}>{props.data.amount}</span>
									</div>
									<div>
										<span className={props.data.type ? "text-success" : "text-danger"}>{languages.type}: </span>
										<span className={props.data.type ? "text-success" : "text-danger"}>{props.data.type ? "Cash In" : "Cash Out"}</span>
									</div>
									<div>
										<span className={props.data.type ? "text-success" : "text-danger"}>{languages.dateTime}: </span>
										<span className={props.data.type ? "text-success" : "text-danger"}>{new Date(props.data.dateTime).toLocaleString()}</span>
									</div>
									<div>
										<span className={props.data.type ? "text-success" : "text-danger"}>{languages.info}: </span>
										<span className={props.data.type ? "text-success" : "text-danger"}>{props.data.information}</span>
									</div>
									<div>
										<span className={props.data.type ? "text-success" : "text-danger"}>Image: </span>
										<span className={styles.imgContainer}>
											{ props?.data?.photo &&
												<img
												src={`${serverPath(props.data.photo)}`}
												className={styles.img}
											/>
											}
										</span>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={props.cancel}>Dismiss</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
    )
};

export default TransactionModal;