import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import languages from "../../localization";
import CashComponent from "../Cash";
import TransactionModal from "../TransactionModal";
import { FaRegTrashAlt } from "react-icons/fa";
import CashIn_Out from "../CashIn_Out";
import SortData, { SortCustomers } from "../../utils/SortData";
import useStore from "../../store/store";
import { AuthContext } from "../../authContext";
import { khataServer } from "../../utils/serverPath";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import { useMediaQuery } from "react-responsive";

const Cashbook = (props) =>
{
	const initState = {
		transactions: [],
		transModal: {visible: false, data: ""},
		cashInOutModal: {visible: false, type: 0},
		totalCashInOut: { cash: 0, cashIn: 0, cashOut: 0 },
		isLoading: false,

		currentItems: [],
		pageCount: 0,
		itemOffset: 0,
		itemsPerPage: 50,
	};
	
	const [ fields, setFields ] = useState(initState);
	const context = useContext(AuthContext);
	const [globalState, dispatch] = useStore();
	const isMobileSize = useMediaQuery({
		query: '(max-width: 600px)'
	  });

	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	const pageCount = Math.ceil(fields?.transactions?.length / fields.itemsPerPage);
	const handlePageClick = (event) => {
		const newOffset = (event.selected * fields.itemsPerPage) % fields.transactions.length;
		onChange(newOffset, "itemOffset");
	};
	
	useEffect(() =>
	{
		const endOffset = fields.itemOffset + fields.itemsPerPage;
		onChange(fields.transactions.slice(fields.itemOffset, endOffset), "currentItems");
		onChange(Math.ceil(fields.transactions.length / fields.itemsPerPage), "pageCount");
	}, [fields.itemOffset, fields.itemsPerPage, fields.transactions]);

	useEffect(() => {
		(async () =>
		{
			if (context.customer && globalState.customers.length <= 0)
			{
				onChange(true, "isLoading");
				const response = await fetch(khataServer("/get/cashbook"), {
					method: "POST",
					headers: {
						"Content-Type": "Application/JSON",
					},
					body: JSON.stringify({providerId: context.user.id, ownerId: context.customer.id})
				});
				const objData = await response.json();
				if (objData.status === "success")
				{
					let data = [];
					objData.data.forEach(async (customer) => {
						if (customer.customerId !== context.customer.id)
							data.push(customer);
					});

					onChange(false, "isLoading");
					onChange(SortCustomers(data), "customers");
					dispatch("setData", {type: 'customers', data: SortCustomers(data)});
				}
			}

			if (globalState.customers.length >= 1 && globalState.transactions.length <= 0)
			{
				let data = [];

				globalState.customers.forEach(async (customer) => {
					const transactionResponse = await fetch(khataServer("/get/cashbook_transactions"), {
						method: "POST",
						headers: {
							"Content-Type": "Application/JSON",
						},
						body: JSON.stringify({ cashbookId: customer.id, currencyId: context?.currency?.id, providerId: context?.user?.id })
					});
					const transObjData = await transactionResponse.json();
					if (transObjData.status === "success")
						data = [...data, ...transObjData.data];

					dispatch("setData", {type: 'transactions', data});
				});
			}
		})();
	}, [context.customer, globalState.customers]);

	useEffect(() =>
	{
		(async() =>
		{
			if (globalState.transactions.length >= 1)
				onChange(SortData(globalState.transactions), "transactions");
		})();
	}, [globalState.transactions]);

	useEffect(() => {
		(async () =>
		{
			if(globalState.customers?.length > 0)
			{
				let cash = { cashIn: 0, cashOut: 0 };
				globalState.customers.forEach(customer => {
					customer.summary?.forEach(per => {
						if(per.currencyId == context.currency?.id)
						{
							cash.cashIn = (cash.cashIn + per.cashIn)
							cash.cashOut = (cash.cashOut + per.cashOut)
						}
					})
				});
				setFields(prev => ({
					...prev,
					totalCashInOut: { cash: cash.cashIn - cash.cashOut, cashIn: cash.cashIn, cashOut: cash.cashOut }
				}))
			}
		})();
	}, [globalState.customers, context.currency.id]);

	const transactionHandler = (value) =>
	{
		const customer = globalState.customers.find(cus => (cus._id || cus.id) === value.cashbookId);
		onChange({visible: true, data: {...value, customerName: [customer.customer.firstName, customer.customer.lastName].join(" ")}}, "transModal")
	}
	
	return (
		<div className="col-lg-12 col-12">

			<CashComponent
				goBack={props.goBack}
				goBackHandler={props.goBackHandler}
				share={props.share}
				shareHandler={() => {}}
				cashs={fields.totalCashInOut}
			/>
			<div className="text-center my-2">
				<button className={["col-lg-3 col-12 bg-success text-white border-0 m-1 py-3 rounded-3", styles.cashBtn].join(" ")} onClick={() => onChange({visible: true, type: 1}, "cashInOutModal")}>Cash In</button>
				<button className={["col-lg-3 col-12 bg-danger text-white border-0 m-1 py-3 rounded-3", styles.cashBtn].join(" ")} onClick={() => onChange({visible: true, type: 0}, "cashInOutModal")}>Cash Out</button>
			</div>
			<div className="row row-cols-3 align-items-center justify-content-center mt-2 shadow-sm text-center mx-0 py-2" style={{backgroundColor: "white"}}>
				<p className="m-0 fw-bold">{languages.dateTime}</p>
				<p className="m-0 fw-bold">{languages.cashIn}</p>
				<p className="m-0 fw-bold">{languages.cashOut}</p>
			</div>

			<div>
				{
					fields.isLoading ?
					<Loader />
					:
					fields.transactions.length >= 1 ?
					<>
						{
							fields?.currentItems.map((value, index) => (
								<div
									className={[styles.cursor_pointer, "row row-cols-3 align-items-center justify-content-center mt-1 shadow-sm text-center mx-0 py-2"].join(" ")}
									key={value.id}
									// onClick={() => onChange({visible: true, data: {...value, customerName: fields.totalCashInOut.customerName}}, "transModal")}
									style={{backgroundColor: "white"}}
									onClick={() => transactionHandler(value)}
								>
									<p className="m-0">{new Date(value.dateTime).toLocaleString()}</p>
									<p className="m-0 text-success">{value.type ? value.amount : ""}</p>
									<p className="m-0 text-danger">{!value.type ? value.amount : ""}</p>
								</div>
							))
						}
						<ReactPaginate
							breakLabel={isMobileSize ? "." : "..."}
							nextLabel={isMobileSize ? ">" : "next >"}
							onPageChange={handlePageClick}
							pageRangeDisplayed={isMobileSize ? 3 : 1}
							pageCount={pageCount}
							previousLabel={isMobileSize ? "<" : "< previous"}
							renderOnZeroPageCount={null}
							containerClassName={styles.paginationContainer}
							pageLinkClassName={styles.pageNum}
							previousLinkClassName={styles.pageNum}
							nextLinkClassName={styles.pageNum}
							activeLinkClassName={styles.activePagi}
						/>
					</>
					:
						<div className="text-center mt-5">
							<p>No Transaction Avaiable</p>
						</div>
				}
			</div>

			<CashIn_Out
				visible={fields.cashInOutModal.visible}
				type={fields.cashInOutModal.type}
				fromCashbook={true}
				cancel={() => onChange({visible: false, type: 0}, "cashInOutModal")}
			/>

			<TransactionModal
				visible={fields.transModal.visible}
				data={fields.transModal.data}
				cancel={() => onChange({visible: false, data: ""}, "transModal")}
			/>
		</div>
	)
};

export default Cashbook;