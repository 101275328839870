import React, { PureComponent, Suspense, lazy } from 'react';
import {Route, Routes} from 'react-router-dom';
import { AuthContext } from '../../authContext';
import Navbar from '../Navbar'
import Home from '../Home'
import PageNotFound from '../FourOFour'
import Profile from '../Profile';
import OpenNews from '../OpenNews';
import News from '../News';
import languages from '../../localization';
import Draw from '../Draw';
import Customer from '../Customer';
import Accounts from '../Accounts';
import NewCustomer from '../NewCustomer';


class Layout extends PureComponent
{

  static contextType = AuthContext;
  render()
  {
    const screens = [
      // {screen: '/aboutus', Component: AboutUs},
      {screen: '/news/:id', Component: OpenNews},
      {screen: '/news', Component: News},
      {screen: '/customer', Component: Customer},
      {screen: '/profile', Component: Profile, login: true},
      {screen: '/draw', Component: Draw, login: true},
      {screen: '/accounts', Component: Accounts, login: true, customer: true},
      {screen: '/new/customer', Component: NewCustomer, login: true, customer: true},
      {screen: '/', Component: Home},
      ]
    
    return (
        <div style={{direction: languages.isRTL ? "rtl" : "ltr"}}>
          <Routes>
            {
              screens.map((per, index) => {
                if(!this.context.user && per.login)
                  return null;

                if(!this.context.customer && per.customer)
                  return null;

              return (
                <Route path={`${per.screen}`} key={index} element={<per.Component />} />
                )})
              }
              <Route path={`*`}  element={<PageNotFound />} />
          </Routes>
        </div>
    )
  }
}


export default Layout;