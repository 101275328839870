import React, {useContext, useEffect, useState} from "react";
import styles from './style.module.css';
import languages from "../../localization";
import { AuthContext } from "../../authContext";
const Footer = (props) =>
{
  const context = useContext(AuthContext)

  return (
    <>
  <svg xmlns="http://www.w3.org/2000/svg" className="fter" viewBox="0 0 1440 320"><path fill="#36363e" fillOpacity="1" d="M0,96L40,117.3C80,139,160,181,240,186.7C320,192,400,160,480,149.3C560,139,640,149,720,176C800,203,880,245,960,250.7C1040,256,1120,224,1200,229.3C1280,235,1360,277,1400,298.7L1440,320L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>            
    <footer className="site-footer section-padding" style={{direction: languages.isRTL ? "rtl" : "ltr"}}>
        <div className="container">
            <div className="row">

                <div className="col-lg-3 col-md-5 col-12 mb-3">
                    {/* <h3><a href="#section_1" className="custom-link mb-1">{languages.millonar}</a></h3> */}
                    <h3><a href="#section_1" className="custom-link mb-1">{languages.brainbbox}</a></h3>

                    {/* <p className="text-white">
                      {languages.millonar_story}
                    </p> */}
                    <p className="text-white">
                      {languages.brainbbox_story}
                    </p>
                    
                    <p className="text-white"><a href="https://www.jahanmal.com" target="_blank">{languages.developers}: {languages.jahanmal_team}</a></p>
                </div>

                <div className="col-lg-3 col-md-3 col-12 ms-lg-auto mb-3">
                    <h3 className="text-white mb-3">{languages.address}</h3>

                    <p className="text-white mt-2">
                        <i className="bi-geo-alt"></i>
                        {languages.kabul}, {languages.afghanistan}
                    </p>
                </div>

                <div className="col-lg-3 col-md-4 col-12 mb-3">
                    <h3 className="text-white mb-3">{languages.contact_info}</h3>

                        <p className="text-white mb-1">
                            <i className="bi-telephone me-1"></i>

                            <a href="tel:+93788622351" className="text-white">
                                {languages.mobile_number}
                            </a>
                        </p>

                        <p className="text-white mb-0">
                            <i className="bi-envelope me-1"></i>

                            <a href="mailto:info@brainbbox.com" className="text-white">
                                info@brainbbox.com
                            </a>
                            {/* <a href="mailto:info@millonar.com" className="text-white">
                                info@millonar.com
                            </a> */}
                        </p>
                </div>

                <div className="col-lg-6 col-md-7 copyright-text-wrap col-12 d-flex flex-wrap align-items-center mt-4 ms-auto">
                    <p className="copyright-text mb-0 me-4">{languages.copy_right} © {languages.jahanmal_team}</p>
                </div>

            </div>
        </div>
    </footer>
    </>
  )
}



export default Footer;