import React, { useContext } from "react";
import styles from "./style.module.css";
import { HiArrowLeft  } from "react-icons/hi";
import languages from "../../localization";
import { AuthContext } from "../../authContext";

const CashComponent = (props) =>
{
	const context = useContext(AuthContext);
    return (
      <div className="shadow-sm row align-items-center justify-content-center rounded-3 text-center mt-3 mx-0 position-relative" style={{backgroundColor: "white"}}>
			{ props.goBack &&
				<div className={styles.backContainer} onClick={props.goBackHandler}>
					<HiArrowLeft />
				</div>
			}
			<div className="col-lg-3 col-md-3 col-3">
				<p>{languages.cashIn}</p>
				<p className="text-success fs-6">{context?.currency?.code} {props.cashs.cashIn}</p>
			</div>
			<div className="col-lg-3 col-md-3 col-3">
				<p>{languages.cash}</p>
				<p className={["text-success fs-6", props.cashs.cash < 0 && "text-danger"].join(" ")}>{context?.currency?.code} {props.cashs.cash}</p>
			</div>
			<div className="col-lg-3 col-md-3 col-3">
				<p>{languages.cashOut}</p>
				<p className="text-danger fs-6">{context?.currency?.code} {props.cashs.cashOut}</p>
			</div>
			{ props.share &&
				<div className={styles.shareContainer} onClick={props.shareHandler}>
					<p className="text-danger">Share</p>
				</div>
			}
		</div>
    )
};

export default CashComponent;