import * as yup from 'yup';
export default (data) =>
{
  let schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().optional().default(""),
    email: yup.string().email().optional().default(""),
    phone: yup.string().required(),
    });

    try {
      schema.validateSync({...data});
      return {status: 'success', data: {...data}};
    } catch (error) {
      return {status: 'failure', message: error.errors[0]}
    }
}
