import {initState} from './store';

const millionaireStore = () =>
{

  const setData = ({type, data}, globalState) => ({[type]: data});

  const initialState = (payload, globalState) => ({
    products: [],
    sliders: [],
    news: [],
    exchanges: [],
    tokens: [],
    drawtokens: [],
    drawdates: [],
    invalidTokens: [],
    cashbooks: [],
    transactions: [],

    customers: [],
    oppositeCustomers: [],
    transactions: [],
    oppositeTransactions: [],
    currencies: [],
    dailyTransactions: [],
    selfCash: [],
    contacts: [],
    circleCash: [],
    payments: []
  });
  
  return initState({
    initialState, 
    setData, 
  })
}


export default millionaireStore;