import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FourOFour = (props) =>
{
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/', {replace: true})
    }, [])
    return (null)
}


export default FourOFour