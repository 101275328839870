import React, { useEffect, useState } from 'react';
import languages from '../localization';
import useStore from '../store/store';
import serverPath, { khataServer } from '../utils/serverPath';

const initialState = {
    user: null,
    customer: null,
    currency: "",
    loading: true,
};

export const AuthContext = React.createContext({
    ...initialState,
    initialState: {...initialState},
    setAuth: (prev) => {},
    setLanguage: (langCode) => {},
    languageCode: "en" || "ps" || "pa"
});


const AuthProvider = (props) =>
{
    const [auth, setAuth] = useState({...initialState});
    const [languageCode, setLanguageCode] = useState("en");
    const [globalState, dispatch] = useStore();


    useEffect(() =>
    {

        const lang = localStorage.getItem("lang");
        if (lang) 
            setLanguage(lang);
        (async() =>
        {
            let user = null
            try {
                const isAuthentecated = await (await fetch(new URL(serverPath("/")).origin + "/auth/login/success", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Accept: "Application/json",
                        "Content-Type": "Application/json",
                        "Access-Control-Allow-Credentials": true
                    }
                })).json();
                if(isAuthentecated.status === "success")
                {
                    user = {
                        id: isAuthentecated.user?.providerId,
                        displayName: isAuthentecated.user?.displayName,
                        provider: isAuthentecated.user?.provider,
                        profile: isAuthentecated.user?.image,
                    }
                }
                const getBulky = await (await fetch(serverPath("/client/bulk"))).json();
                if(getBulky.status === "success")
                    getBulky.data.forEach(perModelData => {
                        dispatch('setData', {...perModelData});
                    });
            } catch (error) {
                console.log(error.message);
            }
            setAuth((prev) => ({...prev, user, loading: false}));
            window.scrollTo({top: 0, behavior: "smooth"})
        })()

    }, []);

    useEffect(() =>
    {
      (async () =>
      {
        if (auth.user && !auth.customer)
        {
            const response = await fetch(khataServer("/get/customer_by_auth"), {
                method: "POST",
                headers: {
                  "Content-Type": "Application/JSON"
                },
                body: JSON.stringify({providerId: auth.user.id})
              });
              const objData = await response.json();
              if (objData.status === "success")
                setAuth((prev) => ({...prev, customer: objData.data}));
        }

        if (globalState.currencies.length <= 0 && auth.user)
        {
          const response = await fetch(khataServer("/get/currency"), {
            method: "POST",
            headers: {
                "Content-Type": "Application/JSON",
            },
            body: JSON.stringify({providerId: auth.user?.id})
          });
  
          const objData = await response.json();
          if (objData.status === "success")
          {
            setAuth((prev) => ({...prev, currency: objData.data[0]}));
            dispatch("setData", {type: 'currencies', data: objData.data});
          }
        }
      })();
    }, [auth.user]);

    const setLanguage = (langCode) =>
    {
        if (langCode == languageCode) {
            return
        }
        languages.setLanguage(langCode);
        setLanguageCode(langCode);
        localStorage.setItem("lang", langCode)

    }


    return (
        <AuthContext.Provider value={{
            ...auth,
            initialState,
            setAuth,
            setLanguage,
            languageCode
         }}>
            {props.children}
        </AuthContext.Provider>
    )
}


export default AuthProvider;