import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { AuthContext } from "../../authContext";
import { khataServer } from "../../utils/serverPath";
import validator from '../../validators/customer';

const ProfileComponent = () =>
{
    const initState = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
		error: "",
		successMsg: ""
    };
	
	const [ fields, setFields ] = useState(initState);
	const context = useContext(AuthContext);

	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	useEffect(() =>
    {
        if (context.user && context.customer)
        {
            setFields({
                firstName: context.customer?.firstName || "",
                lastName: context.customer?.lastName || "",
                phone: context.customer?.phone || "",
                email: context.customer?.email || "",
            });
        }
    }, []);

	useEffect(() =>
	{
		if (fields?.successMsg?.length >= 1)
		{
			setTimeout(() => {
				onChange("", "successMsg");
			}, 5000);
		}
	}, [fields.successMsg]);

	const updateHandler = async () =>
	{
		const {message} = validator(fields);
		if(message)
			return onChange(message, "error");

		if(!fields.phone.match(/^(07[94031]\d{7})$/))
			return onChange("Incorrect number entered!", "error");

		const requestData = {
			firstName: fields.firstName,
			lastName: fields.lastName,
			phone: fields.phone,
			email: fields.email,
		};
			
		const response = await fetch(khataServer('/customer'), {
			method: "PUT",
			headers: {
				"Content-Type": "Application/JSON",
			},
			body: JSON.stringify({...requestData, providerId: context.user.id, id: context?.customer?.id})
		});

		const objData = await response.json();
		console.log(objData);
		if(objData.status === 'success')
		{
			onChange("Profile Successfuly changed", "successMsg");
			context.setAuth(prev => ({...prev, customer: {...context.customer, ...objData.date}}))
		}

		if(objData.status === 'failure')
			onChange(objData.message, "error");
	};


	return (
		<div>
			<div className="col-lg-9 col-12 section-bg-color">
				<div className={["my-3", styles.form].join(" ")}>
					<input type="text" name="first_name" className="form-control shadow-none" placeholder={"First Name"} value={fields.firstName} onChange={({target}) => onChange(target.value, "firstName")} />
					<input type="text" name="last_name" className="form-control shadow-none" placeholder={"Last Name"} value={fields.lastName} onChange={({target}) => onChange(target.value, "lastName")} />
					<input type="text" name="phone" className="form-control shadow-none" placeholder={"Phone"} value={fields.phone} onChange={({target}) => onChange(target.value, "phone")} disabled />
					<input type="text" name="email" className="form-control shadow-none" placeholder={"Email"} value={fields.email} onChange={({target}) => onChange(target.value, "email")} disabled />
				</div>

				<p className='text-danger'>{fields?.error?.length >= 1 && fields.error}</p>
				<p className='text-success'>{fields?.successMsg?.length >= 1 && fields.successMsg}</p>
				<div className="text-start">
				<button className={["col-lg-3 col-12 bg-danger text-white border-0 m-1 my-3 py-3 rounded-3", styles.update].join(" ")} onClick={updateHandler}>Update</button>
			</div>
			</div>
		</div>
	)
};

export default ProfileComponent;