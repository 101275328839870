import React, { useContext, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import styles from './style.module.css';
import {
} from 'react-icons/fa';
import language from '../../localization';
import {AuthContext} from '../../authContext';
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import profile from "../../Assets/draw.jpg";
const Navbar = (props) =>
{
  const {pathname} = useLocation();
  const [globalState] = useStore();
  const context = useContext(AuthContext);
  const {setLanguage, languageCode} = context;
  const [toggler, setToggler] = useState(false);

  const toggleHandler = (boolean = false) =>
  {
    setToggler(boolean);
    if(boolean)
      document.body.style.overflow = "hidden"
    if(!boolean)
    {
      document.body.style.overflow = "auto"
      document.body.style.overflowX = "hidden"
    }
  }


  const initMenu = [
    {
      name: "HOME", 
      mainLink: "/",
      className: "",
      order: 1
    },
    {
      name: "OUR PRODUCTS", 
      mainLink: "/products",
      className: "",
      order: 2
    },
    {
      name: "ABOUT US", 
      mainLink: "/aboutus",
      className: "",
      order: 3
    },
    {
      name: "CONTACT US", 
      mainLink: "/contactus",
      className: "donate",
      order: 4
    },
  ];

  const googleLogin = (e) =>
  {
    window.open(`${new URL(serverPath("/")).origin}/auth/google`, "_self")
  }
  const facebookLogin = (e) =>
  {
    window.open(`${new URL(serverPath("/")).origin}/auth/facebook`, "_self")
  }

  const logoutHandler = (e) =>
  {
    window.open(`${new URL(serverPath("/")).origin}/auth/logout`, "_self")
  }

  return (
    <nav className="navbar navbar-expand-lg bg-light fixed-top shadow-lg">
      <div className={[styles.languages].join(" ")}>
        <div className={styles.grid2} style={{height: "100%"}}>
          <div className={styles.profileContainer}>
            {
              context.user && <>
                <img src={context?.user?.profile} alt="Profile" className={styles.profile} />
                <p className={styles.name}>{context?.user?.displayName}</p></>
            }
          </div>
          <div className={styles.btns}>
            <button className={[styles.langButton,  languageCode === "en" ? styles.activeBTN : ""].join(" ")} onClick={() => setLanguage("en")}>ENGLISH</button>
            <button className={[styles.langButton,  languageCode === "ps" ? styles.activeBTN : ""].join(" ")} onClick={() => setLanguage("ps")}>پښتو</button>
            <button className={[styles.langButton,  languageCode === "pa" ? styles.activeBTN : ""].join(" ")} onClick={() => setLanguage("pa")}>دری</button>
          </div>
        </div>
      </div>
      <div className="container">
        
          {/* <NavLink className="navbar-brand" to="/"><span className="tooplate-red">{language.millonar}</span><span className="tooplate-green"></span></NavLink> */}
          <NavLink className="navbar-brand" to="/"><span className="tooplate-red">{language.brainbbox}</span><span className="tooplate-green"></span></NavLink>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                  { (pathname !== `/profile` && pathname !== `/news` && pathname !== `/draw` && !(pathname.match(/\//ig).length == 2)) ?
                  <>
                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="/#section_1">{language.home}</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link click-scroll" href="/#section_2">{language.products}</a>
                    </li> */}
                    <li className="nav-item">
                        <NavLink className="nav-link click-scroll" to="/news">{language.news}</NavLink>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="/#section_3">{language.about}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="/#section_4">{language.contact}</a>
                    </li>
                  </>
                  :
                  <>
                  <li className="nav-item">
                      <NavLink className="nav-link click-scroll" to="/">{language.home}</NavLink>
                  </li>
                  <li className="nav-item">
                      <NavLink className="nav-link click-scroll" to="/news">{language.news}</NavLink>
                  </li>
                  </>
                  }
                  { context.user ?
                    <li className="nav-item dropdown profile-image">
                        <a 
                          className="nav-link dropdown-toggle click-scroll profileLink" 
                          href="#" 
                          id="navbarLightDropdownMenuLink" 
                          role="button" 
                          data-bs-toggle="dropdown" 
                          aria-expanded="false"
                          style={{padding: 12.5}}
                        >
                          <img
                            className="profileImage"
                            // src={context.user?.image || "/profile.jpg"}
                            src={context?.user?.profile || "/profile.jpg"}
                            alt="Profile"
                            style={{width: 45, borderRadius: 50, boxShadow: "1px 1px 7px rgba(29, 29, 29, 0.2)"}}
                          />
                        </a>
  
                        <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                            <li><NavLink className="dropdown-item" to={'profile'}>{language.profile}</NavLink></li>
                            <li><a className="dropdown-item" href="#" onClick={logoutHandler}>{language.logout}</a></li>
                        </ul>
                    </li>
                    :
                    <li className="nav-item custom-form">
                        <button className="nav-link nav-custom-btn text-danger" onClick={()=>toggleHandler(true)}>{language.login}</button>
                    </li>
                  }
              </ul>
          </div>
      </div>
      { toggler &&
      <div className={styles.modal}>
        <div className={styles.loginWrapper}>
          <div className={styles.wrapperHeader} onClick={() => toggleHandler(false)}>
            <i className="bi-x-square"></i>
          </div>
          <p className={styles.note}>
            {language.you_can_login_or_logout}
          </p>
          <div className={styles.buttons}>
            <button className={[styles.googleButton, styles.mediaButton].join(' ')} onClick={googleLogin}>
                <i className={["bi-google", styles.mediaIcon].join(' ')}></i>
                <span className={styles.mediaText}>SignIn/SignUp with Google</span>
            </button>
            <button className={[styles.facebookButton, styles.mediaButton].join(' ')} onClick={facebookLogin}>
                <i className={["bi-facebook", styles.mediaIcon].join(' ')}></i>
                <span className={styles.mediaText}>SignIn/SignUp with Facebook</span>
            </button>
          </div>
        </div>
      </div>
      }
    </nav>
  )
}



export default Navbar