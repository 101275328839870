import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import languages from "../../localization";
import CashComponent from "../Cash";
import Transactions from "../Transactions";
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import { SortCustomers } from "../../utils/SortData";
import { khataServer } from "../../utils/serverPath";
import Loader from "../Loader";

const Opposite = () =>
{
	const initState = {
		search: "",
		opposites: "",
		showOpposites: true,
		totalCashInOut: { cash: 0, cashIn: 0, cashOut: 0, },
		cashbookId: "",
		isLoading: false,
	};
	
	const [ fields, setFields ] = useState(initState);
	const context = useContext(AuthContext);
	const [globalState, dispatch] = useStore();

	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	const searchHandler = (value) =>
	{
		onChange(value, "search");
		const oppositesClone = [...SortCustomers(globalState.oppositeCustomers)];
		
		if (value.length === 0)
			return onChange(oppositesClone, "opposites");

		let result = [];

		globalState.oppositeCustomers.forEach(customer => {
			if (customer?.owner?.firstName?.toLowerCase()?.search(value?.toLowerCase()) >= 0)
				result.push(customer);
		});

		onChange(SortCustomers(result), "opposites");
	}
	
	
	useEffect(() => {
		(async () =>
		{
			if (context.customer && globalState.oppositeCustomers.length <= 0)
			{
				onChange(true, "isLoading");
				const response = await fetch(khataServer("/get/cashbook"), {
					method: "POST",
					headers: {
						"Content-Type": "Application/JSON",
					},
					body: JSON.stringify({providerId: context.user.id, customerId: context.customer.id})
				});
				const objData = await response.json();
				if (objData.status === "success")
				{
					let data = [];
					objData.data.forEach(async (oppCustomer) => {
						if (oppCustomer.ownerId !== context.customer.id)
							data.push(oppCustomer);
					});

					onChange(false, "isLoading");
					onChange(SortCustomers(data), "opposites");
					dispatch("setData", {type: 'oppositeCustomers', data: SortCustomers(data)});
				}
			}

			if (globalState.oppositeCustomers.length >= 1 && globalState.oppositeTransactions.length <= 0)
			{
				let data = [];

				globalState.oppositeCustomers.forEach(async (customer) => {
					const transactionResponse = await fetch(khataServer("/get/cashbook_transactions"), {
						method: "POST",
						headers: {
							"Content-Type": "Application/JSON",
						},
						body: JSON.stringify({ cashbookId: customer.id, currencyId: context?.currency?.id, providerId: context?.user?.id })
					});
					const transObjData = await transactionResponse.json();
					if (transObjData.status === "success")
						data = [...data, ...transObjData.data];

					dispatch("setData", {type: 'oppositeTransactions', data});
				});
			}

			onChange(SortCustomers(globalState.oppositeCustomers), "opposites");
		})();
	}, [context.customer, globalState.oppositeCustomers]);

	return (
		<div>
			{
				fields.isLoading ?
				<Loader />
				:
				fields.showOpposites ? 
					<div className="col-lg-12 col-12 section-bg-color">
						<div className="input-group align-items-center my-3">
							<input type="text" name="opposite_search" id="opposite_search" className="form-control shadow-none" placeholder={languages.search} required value={fields.search} onChange={({target}) => searchHandler(target.value)} />
						</div>
						<div>
							{
								fields.opposites.length >= 1 ?
									fields?.opposites?.map((value, index) => (
										<div
											className={[styles.cursor_pointer, "shadow-sm row p-2 mx-0 rounded-3 mt-1"].join(" ")}
											onClick={() => setFields(prev => ({...prev, showOpposites: false, cashbookId: value?.summary[0]?.cashbookId || value?._id || value?.id}))}
											style={{backgroundColor: "white"}}
											key={value.id}
										>
											<div className="row row-cols-2 row-cols-sm-6 g-2 g-lg-2 m-0">
												<p className="m-0">Customer: </p>
												<p className="m-0">{value.owner.firstName} {value.owner.lastName}</p>
											</div>
											<div className="row row-cols-2 row-cols-sm-6 g-2 g-lg-2 m-0">
												<p className="m-0">Phone: </p>
												<p className="m-0">{value.owner.phone}</p>
											</div>
										</div>
									))
								:
									<div className="text-center mt-5">
										<p>No Opposite Avaiable</p>
									</div>
							}
						</div>
					</div>
				:
					<Transactions
						goBack
						goBackHandler={() => onChange(true, "showOpposites")}
						cashbookId={fields.cashbookId}
						dontshowCashBTN={true}
						fromOpposite={true}
					/>
			}
		</div>
	)
};

export default Opposite;