import React, { useContext, useEffect, useState } from 'react';
import styles from "./style.module.css";
import { IoIosArrowDown } from "react-icons/io";
import { AuthContext } from "../../authContext"; 
import useStore from '../../store/store';

const CurrencyDropdown = (props) =>
{
  const context = useContext(AuthContext);
  const initState = {
    toggle: false,
    currency: "",
  }

  const [fields, setFields] = useState(initState);
  const [globalState, dispatch] = useStore();

  const onChange = (value, type) =>
  {
    if (type === "currency")
		{
      props.currencyChangeHandler(value);
      setFields(prev => ({ ...prev, "currency": value }))
			return;
		}

    setFields(prev => ({
      ...prev,
      [type]: value,
    }))
  }

  useEffect(() =>
  {
    onChange(context.currency, "currency");
  }, []);

  return (
    <div className={[styles.container, props.className].join(" ")}>
      <div className={styles.content} onClick={() => onChange(!fields.toggle, "toggle")}>
        <span>{fields.currency.code}</span>
        <i className={fields?.toggle ? styles.rotate : ""}><IoIosArrowDown /></i>
      </div>

      <div className={fields.toggle ? [styles.dropdownContent, props.dropdownContentClassName].join(" ") : styles.dropdownContentHide}>
        <ul>
          {
            globalState.currencies.map((value, index) => (
              <li key={index} onClick={() => {
                onChange(value, "currency");
                onChange(!fields.toggle, "toggle");
              }}>{value.code}</li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default CurrencyDropdown;