import React, { useContext, useState } from 'react';
import styles from "./style.module.css";
import languages from '../../localization';
import {AuthContext} from "../../authContext";
import CurrencyDropdown from "../CurrencyDropdown";
import { khataServer } from '../../utils/serverPath';
import useStore from '../../store/store';
import CustomersDropdown from '../CustomersDropdown';
import SweetAlert from '../SweetAlert';

const AddPayment = (props) =>
{
	const context = useContext(AuthContext);
	const initState = {
		amount: "",
		currencyId: context.currency?.id,
		ownerId: context?.customer?.id,
		type: props.type,
		customerId: "",
		dateTime: "",
		error: "",
	};

	const [ fields, setFields ] = useState(initState);
	const [ globalState, dispatch ] = useStore();


	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	const submitHandler = async () =>
	{
		if (fields.amount <= 0)	
			return onChange("Amount is required!", "error");
		else if (!fields.customerId)
			return onChange("Please select a customer!", "error");
		else if (!fields.dateTime)
			return onChange("Please select a Date!", "error");

		let requestData = {
			amount: fields.amount,
			currencyId: fields.currencyId,
			type: props.type ? true : false,
			ownerId: fields.ownerId,
			customerId: fields.customerId,
			dateTime: fields.dateTime,
		}

		const response = await fetch(khataServer("/payment"), {
			method: "POST",
			headers: {
				"Content-Type": "Application/JSON",
			},
			body: JSON.stringify({...requestData, providerId: context.user.id}),
		});

		const objData = await response.json();
		if (objData.status === "success")
		{
			const currencyCode = globalState?.currencies?.find(curr => curr.id === objData.data.currencyId)?.code;
			const customer = globalState.customers.find(cust => cust.customerId === objData.data.customerId);
			const newData = [...globalState.payments, {...objData.data, currencyCode, customer}];
			dispatch("setData", {type: "payments", data: newData});
		}

		if (objData.status === "failure")
		{
			onChange(objData.message, "error");
			return;
		}

		dismissHandler();
	}


	const dismissHandler = () =>
	{
		setFields(initState);
		props.cancel();
	}

	if (!props.visible)
		return;

	const customerChangeHandler = (customer) => {
		onChange(customer.customerId, "customerId");
	}

    return (
		<>
			{props.visible && (
				<div className={[styles.container, "modal show "].join(" ")} style={{ display: 'block' }} tabIndex="-1">
					<div className="modal-dialog justify-content-center">
						<div className="modal-content m-auto">
							<div className="p-3">
								<p className={props.type ? "text-success" : "text-danger"}>Add Payment</p>
								<div>
									<div className={[styles.form, "row px-2"].join(" ")}>
										<input
											style={props.type ? {border: "1px solid green"} : {border: "1px solid rgba(255, 0, 0, 0.5)"}}
											type='number'
											placeholder='Amount'
											value={fields.amount}
											onChange={({target}) => onChange(target.value, "amount")}
										/>
										<CurrencyDropdown currencyChangeHandler={(currency) => onChange(currency.id, "currencyId")} className={styles.cDropdown} dropdownContentClassName={styles.cDropdownContent} />
										<CustomersDropdown customerChangeHandler={(customer) => customerChangeHandler(customer)} className={styles.cDropdown} dropdownContentClassName={styles.cDropdownContent} />
										<input
											style={props.type ? {border: "1px solid green"} : {border: "1px solid rgba(255, 0, 0, 0.5)"}}
											type='date'
											placeholder='Date Time'
											value={fields.dateTime}
											onChange={({target}) => onChange(target.value, "dateTime")}
										/>
									</div>
									<p className='text-danger'>{fields.error.length >= 1 && fields.error}</p>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={dismissHandler}>Dismiss</button>
								<button type="button" className={props.type ? "btn btn-success col-4" : "btn btn-danger col-4"} onClick={submitHandler}>Submit</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
    )
};

export default AddPayment;