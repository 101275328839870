import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styles from './style.module.css'
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import languages from "../../localization";

const News = (props) =>
{
    const navigate = useNavigate()
    const [globalState] = useStore();
    const {news} = globalState;
    const [pagination, setPagination] = useState({
        pageNumber: 1,
        limit: 9,
        totalItems: 0,
        totalPages: 0
    });
    useEffect(() => {
        setPagination(prev => ({
            ...prev, 
            totalItems: news.length, 
            totalPages: Math.ceil(news.length / prev.limit)
        }))
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [news]);

    
    const createCustomerAcc = async () =>
    {
        try {

            const response = await fetch(serverPath("/admin/khata/customer"), {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    firstName: "Ahmadullah",
                    lastName: "Khan",
                    phone: "07010319511",
                    email: 'ahmadu1llah@gmail.com',
                    type: "own",
                })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }
    
    const updateCustomerAcc = async () =>
    {

        try {

            const response = await fetch(serverPath("/khata/customer"), {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    firstName: "Khalid",
                    lastName: "Khan",
                    phone: "0744488811",
                    email: 'khalid@gmail.com',
                    id: 2
                    
                })
                // body: JSON.stringify({
                //     firstName: "AHMADULLAH",
                //     lastName: "Khan",
                //     phone: "0744488816",
                //     email: "abcd@gmail.com",
                //     id: 11
                    
                // })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }
    
    const deleteCustomerAcc = async () =>
    {

        try {

            const response = await fetch(serverPath("/khata/customer"), {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    id: 2
                })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }
    
    const getCustomerAcc = async () =>
    {

        try {

            const response = await fetch(serverPath("/khata/get/customer"), {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "Application/json",
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Credentials": true
                },
                body: JSON.stringify({
                    phone: "0744488811"
                })
            });

            const objData = await response.json();
            console.log(objData)
            
        } catch (error) {
            console.log(error.message);
        }
        
    }
    return (
        <main>
            <header className="site-header d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                            <h1 className="text-white">{languages.news}</h1>
                        </div>

                    </div>
                </div>
            </header>
            {/* <button type="submit" className={[`form-control btn btn-primary`].join(' ')} onClick={createCustomerAcc}>{"Create Customer Account"}</button> */}
            {/* <button type="submit" className={[`form-control btn btn-warning mt-3`].join(' ')} onClick={updateCustomerAcc}>{"Update Customer Account"}</button> */}
            {/* <button type="submit" className={[`form-control btn btn-danger mt-3`].join(' ')} onClick={deleteCustomerAcc}>{"Delete Customer Account"}</button> */}
            {/* <button type="submit" className={[`form-control btn btn-danger mt-3`].join(' ')} onClick={getCustomerAcc}>{"Get Customer Account"}</button> */}
                    
            <section className="shop-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-5"><strong>{languages.welcome_to_news}</strong></p>
                            <div className="row">
                                {[...news].reverse().slice((pagination.pageNumber * pagination.limit) - pagination.limit, (pagination.pageNumber * pagination.limit)).map(nws => (
                                <div className="col-md-4 col-12" key={nws.id}>
                                    <div className="shop-thumb">
                                        <div className="shop-image-wrap">
                                            <NavLink to={`/news/${nws.id}`}>
                                                <img src={serverPath(nws.photo)} className="shop-image img-fluid" alt="image" />
                                            </NavLink>
                                            <div className="shop-btn-wrap">
                                                <NavLink to={`/news/${nws.id}`} className="shop-btn custom-btn btn d-flex align-items-center align-items-center">{languages.learn_more}</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}

                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={["page-item", pagination.pageNumber > 1 ? "active" : ''].join(' ')}>
                                            <button className="page-link" aria-label="Previous" disabled={(pagination.pageNumber <= 1)} onClick={() =>
                                            {
                                                setPagination(prev => ({...prev, pageNumber: prev.pageNumber <= 1 ? 1 : (prev.pageNumber - 1)}))
                                            }}>
                                                <span aria-hidden="true">&laquo;</span>
                                            </button>
                                        </li>

                                        <li className={["page-item", (pagination.pageNumber < pagination.totalPages) ? "active" : ''].join(' ')}>
                                            <button className="page-link" aria-label="Next" disabled={(pagination.pageNumber >= pagination.totalPages)} onClick={() =>
                                            {
                                                setPagination(prev => ({...prev, pageNumber: prev.pageNumber >= prev.totalPages ? prev.totalPages : (prev.pageNumber + 1)}))
                                            }}>
                                                <span aria-hidden="true">&raquo;</span>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}


export default News;