import React from "react";
import styles from "./style.module.css";
import { useContext } from "react";
import { AuthContext } from "../../authContext";
import { useState } from "react";
import AddPayment from "../AddPayment";
import useStore from "../../store/store";

const Payment = (props) =>
{
	const context = useContext(AuthContext);
	const [globalState, dispatch] = useStore();
	const [addPaymentVisible, setAddPaymentVisible] = useState({visible: false, type: 0});

	return (
		<div className="my-3" style={{backgroundColor: "white", padding: 10, borderRadius: 5}}>
			<div className="text-center my-2">
				<button className={["col-lg-3 col-12 bg-success text-white border-0 m-1 py-3 rounded-3", styles.cashBtn].join(" ")} onClick={() => setAddPaymentVisible({visible: true, type: 1})}>Add Payment</button>
				<button className={["col-lg-3 col-12 bg-danger text-white border-0 m-1 py-3 rounded-3", styles.cashBtn].join(" ")} onClick={() => setAddPaymentVisible({visible: true, type: 0})}>Add Payment</button>
			</div>

			<AddPayment
				visible={addPaymentVisible.visible}
				type={addPaymentVisible.type}
				cancel={() => setAddPaymentVisible({visible: false, type: 0})}
				submitHandler={() => setAddPaymentVisible({visible: false, type: 0})}
			/>
	</div>
	)
};

export default Payment;