import React, {useEffect, useContext, useState} from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import languages from "../../localization";
import { useNavigate } from "react-router-dom";
import serverPath from "../../utils/serverPath";
import SweetAlert from '../../Components/SweetAlert';
import validator from '../../validators/customer'
import useStore from "../../store/store";
import fetchApi from '../../utils/fetchApi'
const NewCustomer = (props) =>
{
    const navigate = useNavigate();
    const [globalState, dispatch] = useStore();
    const {user, customer, setAuth} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const initFields = {
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    };

    const [fields, setFields] = useState({...initFields});

    useEffect(() => {

    }, [])


    const onChange = (value, type) => {
      if(type === 'phone')
        return setFields(prev => ({...prev, [type]: value?.replace(/\D/ig, "")}))
      setFields(prev => ({...prev, [type]: value}))
    }
    // /^(07[94031]\d{7})$/

    const onSubmit = async(e) =>
    {
      e.preventDefault()
      try {
        if(!user)
          return navigate("/", {replace: true});
        const {message} = validator(fields);

        if(message)
          return SweetAlert("warning", message);

        if(!fields.phone.match(/^(07[94031]\d{7})$/))
          return SweetAlert("info", 'Invalid Phone Number Entered');

        setIsLoading(true);
        const response = await fetchApi({
          path: "/khata/customer",
          method: "POST",
          body: {...fields, type: "customer"}
        })

        const isCustomerExist = await response.json();
        if(isCustomerExist.status == 'success')
          if(isCustomerExist.data)
          {
            const createKhataResponse = await fetchApi({
              path: "/khata/cashbook",
              method: "POST",
              body: {
                ownerId: customer.id,
                customerId: isCustomerExist.data.id
              }
            })
            
            const createKhata = await createKhataResponse.json();
            if(createKhata.status === 'failure')
              return SweetAlert("warning", createKhata.message).then( () => navigate("/accounts", {replace: true}))

            if(createKhata.status === 'success')
              dispatch('setData', {type: "cashbooks", data: {...createKhata.data, customer: isCustomerExist.data, owner: customer}});

            return SweetAlert("success", "Account Successfully created").then( () => navigate("/customer", {replace: true}))
          }

        if(isCustomerExist.status == 'failure')
          SweetAlert('info', isCustomerExist.message)

      } catch (error) {
        SweetAlert("warning", error.message)
      }
      setIsLoading(false);
    }

  return (
    <main className="container pt-5">
      <section className="section" style={{maxWidth: 760, display: "grid", margin: "50px auto"}}>
        {
          isLoading ?
          <p>Please Wait Until Loading...</p>
          :
          <div className="newCustomer">
            <form className="mt-5" onSubmit={onSubmit}>
              <p>
                Add Customer
              </p>
              <div className="row mb-3">
                <div className="form-group col-md-6">
                  <label htmlFor="firstname">{languages.first_name} *</label>
                  <input type="text" className="form-control" id="firstname" placeholder={`${languages.first_name}`} required value={fields.firstName} onChange={({target})=>onChange(target.value, "firstName")} />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="lastname">{languages.last_name}</label>
                  <input type="text" className="form-control" id="lastname" placeholder={languages.last_name}  value={fields.lastName} onChange={({target})=>onChange(target.value, "lastName")} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">{languages.email_address}</label>
                  <input type="email" className="form-control" id="inputEmail4" placeholder={languages.email_address}  value={fields.email} onChange={({target})=>onChange(target.value, "email")} />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="phone">{languages.phone} *</label>
                  <input type="text" className="form-control" id="phone" placeholder={languages.phone} required value={fields.phone} min={10} max={10} onChange={({target})=>onChange(target.value, "phone")} />
                </div>
              </div>
              <button type="submit" className="btn btn-danger">Add Customer</button>
            </form>
          </div>

        }
      </section>
    </main>
  )
}



export default NewCustomer;