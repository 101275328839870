import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import languages from "../../localization";
import CashComponent from "../Cash";
import Transactions from "../Transactions";
import {khataServer} from "../../utils/serverPath";
import {AuthContext} from "../../authContext";
import useStore from "../../store/store";
import {SortCustomers} from "../../utils/SortData";
import Loader from "../../Components/Loader";
import { useMediaQuery } from "react-responsive";

const Customers = () =>
{
	const initState = {
		search: "",
		customers: "",
		showCustomers: true,
		totalCashInOut: { cash: 0, cashIn: 0, cashOut: 0, },
		cashbookId: "",
		isLoading: false,
	};
	
	const [ fields, setFields ] = useState(initState);
	const context = useContext(AuthContext);
	const [globalState, dispatch] = useStore();
	const isMobileSize = useMediaQuery({
		query: '(max-width: 600px)'
	  });

	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	const searchHandler = (value) =>
	{
		onChange(value, "search");
		const customersClone = [...SortCustomers(globalState.customers)];
		
		if (value.length === 0)
			return onChange(customersClone, "customers");

		let result = [];
		customersClone.forEach(objData => {
				if (objData.customer?.firstName?.toLowerCase()?.search(value?.toLowerCase()) >= 0) {
					result.push(objData);
				};
		});

		onChange(SortCustomers(result), "customers");
	}

	useEffect(() => {
		(async () =>
		{
			if (context.customer && globalState.customers.length <= 0)
			{
				onChange(true, "isLoading");
				const response = await fetch(khataServer("/get/cashbook"), {
					method: "POST",
					headers: {
						"Content-Type": "Application/JSON",
					},
					body: JSON.stringify({providerId: context.user.id, ownerId: context.customer.id})
				});
				const objData = await response.json();
				if (objData.status === "success")
				{
					let data = [];
					objData.data.forEach(async (customer) => {
						if (customer.customerId !== context.customer.id)
							data.push(customer);
					});

					onChange(false, "isLoading");
					onChange(SortCustomers(data), "customers");
					dispatch("setData", {type: 'customers', data: SortCustomers(data)});
				}
			}

			if (globalState.customers.length >= 1 && globalState.transactions.length <= 0)
			{
				let data = [];

				globalState.customers.forEach(async (customer) => {
					const transactionResponse = await fetch(khataServer("/get/cashbook_transactions"), {
						method: "POST",
						headers: {
							"Content-Type": "Application/JSON",
						},
						body: JSON.stringify({ cashbookId: customer.id, currencyId: context?.currency?.id, providerId: context?.user?.id })
					});
					const transObjData = await transactionResponse.json();
					if (transObjData.status === "success")
						data = [...data, ...transObjData.data];

					dispatch("setData", {type: 'transactions', data});
				});
			}

			onChange(SortCustomers(globalState.customers), "customers");
		})();
	}, [context.customer, globalState.customers]);

	useEffect(() => {
		if(globalState.customers.length > 0)
		{
			let cash = { cashIn: 0, cashOut: 0 };
			globalState.customers.forEach(customer => {
				customer.summary?.forEach(per => {
					if(per.currencyId == context.currency?.id)
					{
						cash.cashIn = (cash.cashIn + per.cashIn)
						cash.cashOut = (cash.cashOut + per.cashOut)
					}
				})
			});
			setFields(prev => ({
				...prev,
				totalCashInOut: { cash: cash.cashIn - cash.cashOut, cashIn: cash.cashIn, cashOut: cash.cashOut }
			}))

			let circleData = [];
			globalState.customers.forEach(per =>
			{
				per.summary.forEach(summ =>
					{
						const isExist = circleData.findIndex(data => data.currencyId === summ.currencyId);
						if (isExist >= 0)
						{
							circleData[isExist] = {cashIn: circleData[isExist].cashIn+summ.cashIn, cashOut: circleData[isExist].cashOut+summ.cashOut, currencyId: summ.currencyId, currencyCode: circleData[isExist].currencyCode, cash: 0};
							return;
						};

						const currencyInfo = globalState.currencies.find(curr => curr.id === summ.currencyId);
						circleData.push({cashIn: summ.cashIn, cashOut: summ.cashOut, currencyId: summ.currencyId, currencyCode: currencyInfo?.code, cash: 0});
					})
			});

			dispatch("setData", {type: "circleCash", data: circleData});
		};
	}, [globalState.customers, context.currency]);


	return (
		<div>
			{
				fields.showCustomers ? 
					<div className="col-lg-12 col-12 section-bg-color">
						<div className="input-group align-items-center mt-3">
							<input type="text" name="customer_search" id="customer_search" className="form-control shadow-none" placeholder={languages.search} required value={fields.search} onChange={({target}) => searchHandler(target.value)} />
						</div>
						<CashComponent cashs={fields.totalCashInOut} />
						<div>
							{
								fields.isLoading ?
								<Loader />
								:
								fields.customers.length >= 1 ?
									fields?.customers?.map((value, index) => (
										<div
											className={[styles.cursor_pointer, "shadow-sm row p-2 mx-0 rounded-3 mt-1"].join(" ")}
											style={{backgroundColor: "white"}}
											onClick={() => setFields(prev => ({...prev, showCustomers: false, cashbookId: value?.summary[0]?.cashbookId || value?._id || value?.id}))}
											key={value.id}
										>
											<div className="row row-cols-2 row-cols-sm-6 g-2 g-lg-2 m-0">
												<p className="m-0">Customer: </p>
												<p className="m-0">{value.customer.firstName} {value.customer.lastName}</p>
											</div>
											<div className="row row-cols-2 row-cols-sm-6 g-2 g-lg-2 m-0">
												<p className="m-0">Phone: </p>
												<p className="m-0">{value.customer.phone}</p>
											</div>
										</div>
									))
								:
									<div className="text-center mt-5">
										<p>No Customer Avaiable</p>
									</div>
							}
						</div>
					</div>
				:
					<Transactions
						goBack
						goBackHandler={() => onChange(true, "showCustomers")}
						share
						trash
						trashHandler={() => console.log("WELCOME")}
						fromDate_ToDate
						cashbookId={fields.cashbookId}
						isMobileSize={isMobileSize}
					/>
			}
		</div>
	)
};

export default Customers;